//remove "blanc et mettre entre paranthèes havane marron"
import hamburger from '../icons/menu.svg'
import close from "../icons/close.svg"
import { useEffect, useState } from 'react'
import Header from './header'
import Footer from './footer'
import { Link } from 'react-router-dom'
import downArrow from "../icons/down-arrow.svg"
import { useSwipeable } from "react-swipeable"

function TheBoat(params) {
    const isMobile = (params.ratio.width < 600 || params.ratio.width < params.ratio.height) ? true : false
    const [scrollPos, setScrollPos] = useState(0)
    const [displayHeader, setDisplayHeader] = useState(true)
    const [displayMobileMenu, setDisplayMobileMenu] = useState(false)
    const [burgerOrClose, setBurgerOrClose] = useState(hamburger)

    const [selectedPic, setSelectedPic] = useState("")
    const [picListFromFTPOK, setPicListFromFTPOK] = useState(false)
    const [picListFromAPIOK, setPicListFromAPIOK] = useState(false)

    const [picList, setPicList] = useState([])

    const [picListConfig, setPicListConfig] = useState([])

    const [listToDisplay, setListToDisplay] = useState([])
    const [renderMobileDisplayer, setRenderMobileDisplayer] = useState(false)

    const [actualIndex, setActualIndex] = useState(0)

    console.log('list to display:', listToDisplay)

    function handleScroll() {
        if (window.scrollY > scrollPos) {
            if (window.scrollY > 50) {
                setDisplayHeader(false)
                setDisplayMobileMenu(false)
            }
            setScrollPos(window.scrollY)
        } else {
            setDisplayHeader(true)
            setScrollPos(window.scrollY)
        }
        let bg = document.getElementById("main-bg")
        if (window.scrollY < 1000 && window.scrollY > 200) {
            if (bg) {
                bg.style.filter = `brightness(120%) blur(${Math.floor((window.scrollY - 200) / 100)}px)`
            }
        } else if (window.scrollY <= 200) {
            if (bg) {
                bg.style.filter = `brightness(120%) blur(0px)`
            }
        }
    }

    useEffect(() => {
        fetch(`https://boreasapi.cyrilmorin.fr:${params.servPort}/getboreaspiclist`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                return res.json();
            })
            .then(data => {
                let picListFromServ = data.filter(d => (d.name !== "." && d.name !== ".."))
                setPicList(picListFromServ)
                setPicListFromFTPOK(true)
            })
        fetch(`https://boreasapi.cyrilmorin.fr:${params.servPort}/getboreaspiclistconfig`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                return res.json();
            })
            .then(data => {
                setPicListConfig(data)
                setPicListFromAPIOK(true)
            })
    }, [])

    useEffect(() => {
        if (picListFromFTPOK && picListFromAPIOK) {
            let newLst = []
            if (picListConfig) {
                picListConfig.forEach(pic => {
                    let found = picList.find(e => e.name === pic.name)
                    if (found && pic.isDisplayed) {
                        newLst.push(pic)
                    }
                })
            }
            if (picList) {
                picList.forEach(pic => {
                    let found = picListConfig.find(e => e.name === pic.name)
                    if (!found) {
                        newLst.push({
                            name: pic.name,
                            isDisplayed: true
                        })
                    }
                })
            }
            if (newLst.length > 0) {
                setListToDisplay(newLst)
                setSelectedPic(newLst[0])
            }
        }
    }, [picListFromAPIOK, picListFromFTPOK])

    useEffect(() => {
        window.addEventListener("scroll", handleScroll)
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [scrollPos])

    useEffect(() => {
        if (displayMobileMenu) {
            setBurgerOrClose(close)
        } else {
            setBurgerOrClose(hamburger)
        }
    }, [displayMobileMenu])

    useEffect(() => {
        if (listToDisplay && listToDisplay.length > 0) {
            setSelectedPic(listToDisplay[actualIndex])
        }
    }, [actualIndex])

    return (
        <div id="the-boat-main-wrapper">
            <div id="main-bg">

            </div>
            <Header
                isMobile={isMobile}
                displayHeader={displayHeader}
                setDisplayMobileMenu={setDisplayMobileMenu}
                displayMobileMenu={displayMobileMenu}
                burgerOrClose={burgerOrClose}
            />
            {
                renderMobileDisplayer &&
                <MobilePicDisplayer
                    actualIndex={actualIndex}
                    setActualIndex={setActualIndex}
                    listToDisplay={listToDisplay}
                    selectedPic={selectedPic}
                    ratio={params.ratio}
                    setRenderMobileDisplayer={setRenderMobileDisplayer}
                />
            }
            <div id="the-boat-upper-wrapper">
                <h1 id="the-boat-main-title">Le Boreas</h1>
                <div id="the-boat-upper-links">
                    <div className="the-boat-upper-links-text-wrapper"
                        style={{
                            backgroundColor: "rgba(0, 0, 0, 0.623)",
                            borderRadius: "20px"
                        }}
                    >
                        <p

                            className="the-boat-upper-links-text">Le bateau</p>
                    </div>
                    <div className="the-boat-upper-links-text-wrapper">
                        <p className="the-boat-upper-links-text"><Link to="/stops">Les escales</Link></p>
                    </div>
                </div>
                <div id="the-boat-main-pic-main-text">
                    <div id="the-boat-main-pic"
                        onClick={() => {
                            if (isMobile) {
                                setRenderMobileDisplayer(true)
                            }
                        }}
                    >
                        {
                            isMobile &&
                            <img src={downArrow}
                                style={{
                                    transform: "rotate(90deg)",
                                    height: "18px",
                                    width: "20px",
                                    position: 'absolute',
                                    top: "47%",
                                    left: "0%",
                                    padding: "3px",
                                    border: "none"
                                }}
                            />
                        }
                        {
                            isMobile &&
                            <img src={downArrow}
                                style={{
                                    transform: "rotate(270deg)",
                                    height: "18px",
                                    width: "20px",
                                    position: 'absolute',
                                    top: "47%",
                                    right: "0%",
                                    padding: "3px",
                                    border: "none"
                                }}
                            />
                        }
                        <img alt="le-boreas" src="https://pics.boreas.paris/leboreas/2.jpg"
                        />
                    </div>
                    <div id="the-boat-main-text">
                        {/* <p className="the-boat-story-text">
                            Authentique bateau centenaire, transformé en un navire de croisière à pont de bois navigant dans Paris,

                            Proposant un espace d´une superficie totale de 700 m2 répartie sur trois ponts, vous pourrez célébrer cette nouvelle étape de votre vie en grand groupe. Il peut recevoir jusqu´à 300 passagers en restauration assise et 400 pour un cocktail. Le bateau est entièrement climatisé et insonorisé sur les deux ponts fermés ainsi vous devrez trouver le confort dont vous avez besoin.

                            Ce spacieux bateau dispose de trois ponts aménageables pour votre réception : le pont principal, le pont supérieur et le pont inférieur.

                            Tout d´abord le pont principal, très vaste, a les caractéristiques suivantes :
                        </p>
                        <p className="the-boat-story-text">
                            Il dispose d´un salon de 300 m2. Entièrement vitrée, cette salle offre une vue splendide sur tout Paris et apporte énormément de lumière naturelle. Néanmoins, le salon bénéficie d’un éclairage réglable pour les soirées ou les journées plus sombres.
                        </p>
                        <p className="the-boat-story-text">
                            Profitez du comptoir de bar du 19ème siècle pour votre vin d´honneur.
                        </p>
                        <p className="the-boat-story-text">
                            La salle dispose de tables ovales et rectangulaires apportant une ambiance conviviale.
                        </p>
                        <p className="the-boat-story-text">
                            250 chaises en cuir blanc seront mises à votre disposition.
                        </p>
                        <p className="the-boat-story-text">
                            Ensuite montez sur le pont supérieur :
                        </p>
                        <p className="the-boat-story-text">
                            Sur le pont supérieur vous pourrez admirer Paris et sa plus belle avenue, la Seine. Cette terrasse a une superficie de 330 m2 et est considérée comme l´une des plus grandes terrasses de la capitale. Un double escalier rend son accès facile

                            Puis le pont inférieur d'une superficie de 70 m2. Il inclut un espace traiteur équipé. Aussi à disposition un endroit modulable pouvant accueillir un vestiaire et une loge.
                        </p> */}
                        <p className="the-boat-story-text">Authentique bateau centenaire, transformé en un navire de croisière à pont de bois navigant dans Paris.

Proposant un espace d´une superficie totale de 700 m2 répartie sur trois ponts, vous pourrez célébrer vos évènements en grand nombre.

Peut-être une nouvelle étape de votre vie.</p>
<p className="the-boat-story-text">Il peut recevoir jusqu´à 200 passagers en restauration assise et 400 pour un cocktail.</p>
<p className="the-boat-story-text">Le bateau est entièrement climatisé et insonorisé sur les deux ponts fermés. Ainsi vous trouverez tout le confort nécessaire pour recevoir vos convives.

Ce spacieux bateau dispose de trois ponts aménageables pour votre réception : le pont principal, le pont supérieur et le pont inférieur. </p>
<p className="the-boat-story-text">Tout d´abord le pont principal, très vaste, a les caractéristiques suivantes :

Il dispose d´un salon de 300 m2, entièrement vitrée. Cette salle offre une vue splendide sur tout Paris et apporte énormément de lumière naturelle.

Elle dispose aussi d’un éclairage réglable pour les soirées.

Profitez d’un comptoir de bar du 19ème siècle pour votre vin d´honneur.

Le Boreas met à disposition des tables ovales et rectangulaires, 250 chaises en cuir ton havane, apportant une ambiance conviviale.</p>
<p className="the-boat-story-text">Ensuite montez sur le pont supérieur :

Sur cette terrasse, vous pourrez admirer Paris et sa plus belle avenue, la Seine.

D’une superficie de 330 m2, elle est considérée comme l´une des plus grandes terrasses de la capitale.

Un double escalier rend son accès facile.</p>
<p className="the-boat-story-text">Le pont inférieur d'une superficie de 70 m2, inclut un espace traiteur équipé.

Aussi à disposition un endroit modulable pouvant accueillir un vestiaire et une loge.</p>
                    </div>
                </div>
            </div>
            {
                !isMobile &&
                <h2 id="the-boat-pictures-title">Les Photos</h2>
            }
            {
                !isMobile &&
                <div id="the-boat-displayed-pic-main-wrapper">
                    <div id="the-boat-displayed-pic-wrapper">
                        <div
                            style={{
                                width: "1%",
                                height: "100%",
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'absolute',
                                top: 0,
                                left: "15px",
                            }}
                        >
                            {
                                actualIndex > 0 &&
                                <img src={downArrow}
                                    style={{
                                        transform: "rotate(90deg)",
                                        height: "20px",
                                        marginBottom: "50px",
                                    }}
                                    onClick={() => {
                                        setActualIndex(actualIndex - 1)
                                        let container = document.getElementById("the-boat-pic-selector")
                                        if (container) {
                                            container.scrollBy({ left: -160 })
                                        }
                                    }}
                                />
                            }
                        </div>
                        <img
                            id="the-boat-displayed-pic"
                            src={`https://pics.boreas.paris/leboreas/${selectedPic.name ? selectedPic.name : "2.jpg"}`}
                        />
                        <div
                            style={{
                                width: "1%",
                                height: "100%",
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'absolute',
                                top: 0,
                                right: "15px",
                            }}
                        >
                            {
                                actualIndex < listToDisplay.length - 1 &&
                                <img src={downArrow}
                                    style={{
                                        transform: "rotate(270deg)",
                                        height: "20px",
                                        marginBottom: "50px",
                                    }}
                                    onClick={() => {
                                        setActualIndex(actualIndex + 1)
                                        let container = document.getElementById("the-boat-pic-selector")
                                        if (container) {
                                            container.scrollBy({ left: 160 })
                                        }
                                    }}
                                />
                            }
                        </div>
                    </div>
                    <div id="the-boat-pic-selector-wrapper">
                        <div
                            style={{
                                width: "1%",
                                height: "100%",
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            {
                                actualIndex > 0 &&
                                <img src={downArrow}
                                    style={{
                                        transform: "rotate(90deg)",
                                        height: "20px",
                                        marginBottom: "50px",
                                    }}
                                    onClick={() => {
                                        setActualIndex(actualIndex - 1)
                                        let container = document.getElementById("the-boat-pic-selector")
                                        if (container) {
                                            container.scrollBy({ left: -160 })
                                        }
                                    }}
                                />
                            }
                        </div>
                        <div id="the-boat-pic-selector">
                            {
                                listToDisplay.map((pic, i) => {
                                    return (
                                        <div
                                            key={pic.name}
                                            className="pic-wrapper-in-pic-selector"
                                        >
                                            <img
                                                className="pic-in-pic-selector"
                                                src={`https://pics.boreas.paris/leboreas/${pic.name}`}
                                                style={{
                                                    filter: selectedPic.name === pic.name ? "brightness(100%)" : "brightness(60%)",
                                                }}
                                                id={pic.name}
                                                onClick={() => {
                                                    if (actualIndex < i) {
                                                        let container = document.getElementById("the-boat-pic-selector")
                                                        if (container) {
                                                            container.scrollBy({ left: 160 })
                                                        }
                                                    } else if (actualIndex > i) {
                                                        let container = document.getElementById("the-boat-pic-selector")
                                                        if (container) {
                                                            container.scrollBy({ left: -160 })
                                                        }
                                                    }
                                                    setActualIndex(i)
                                                }}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div
                            style={{
                                width: "1%",
                                height: "100%",
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            {
                                actualIndex < listToDisplay.length - 1 &&
                                <img src={downArrow}
                                    style={{
                                        transform: "rotate(270deg)",
                                        height: "20px",
                                        marginBottom: "50px",
                                    }}
                                    onClick={() => {
                                        setActualIndex(actualIndex + 1)
                                        let container = document.getElementById("the-boat-pic-selector")
                                        if (container) {
                                            container.scrollBy({ left: 160 })
                                        }
                                    }}
                                />
                            }
                        </div>
                    </div>
                </div>
            }
            {
                // isMobile &&
                // <div id="the-boat-mobile-pic-main-wrapper">
                //     <div id="the-boat-mobile-pic-wrapper">
                //     </div>
                // </div>
            }
            <Footer></Footer>
        </div>
    )
}

function MobilePicDisplayer(params) {
    const handlers = useSwipeable({
        onSwipedLeft: () => {
            if (params.actualIndex < params.listToDisplay.length - 1) {
                params.setActualIndex(params.actualIndex + 1)
            }
        },
        onSwipedRight: () => {
            if (params.actualIndex > 0) {
                params.setActualIndex(params.actualIndex - 1)
            }
        },
    });

    return (
        <div id="mobile-pic-displayer-wrapper">
            <div
                {...handlers}
                id="mobile-displayed-pic-wrapper">
                {
                    params.actualIndex > 0 &&
                    <img src={downArrow}
                        style={{
                            transform: "rotate(90deg)",
                            height: "20px",
                            width: "22px",
                            position: 'absolute',
                            top: "47%",
                            left: "8px",
                        }}
                        onClick={() => {
                            if (params.actualIndex > 0) {
                                params.setActualIndex(params.actualIndex - 1)
                            }
                        }}
                    />
                }
                {
                    params.actualIndex < params.listToDisplay.length - 1 &&
                    <img src={downArrow}
                        style={{
                            transform: "rotate(270deg)",
                            height: "20px",
                            width: "22px",
                            position: 'absolute',
                            top: "47%",
                            right: "8px",
                        }}
                        onClick={() => {
                            if (params.actualIndex < params.listToDisplay.length - 1) {
                                params.setActualIndex(params.actualIndex + 1)
                            }
                        }}
                    />
                }
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <div
                        className="mobile-displayed-pic-surrounding-div"
                        style={{
                            width: "100%"
                        }}
                        onClick={() => params.setRenderMobileDisplayer(false)}
                    />
                    <img
                        onLoad={(e) => {
                            let rect = e.target.getBoundingClientRect()
                            let sruDivs = document.getElementsByClassName("mobile-displayed-pic-surrounding-div")
                            console.log("surdivs")
                            console.log(sruDivs)
                            if (sruDivs) {
                                console.log("in for surdivs")
                                for (let i = 0; i < sruDivs.length; i++) {
                                    sruDivs[i].style.height = `${Math.floor(params.ratio.height - rect.height) / 2}px`
                                    console.log("surdivs height")
                                    console.log(Math.floor(params.ratio.height - rect.height) / 2)
                                }
                            }

                        }}
                        id="the-boat-mobile-displayed-pic-in-displayer"
                        style={{
                            width: "100%"
                        }}
                        src={`https://pics.boreas.paris/leboreas/${params.selectedPic.name}`} />
                    <div
                        className="mobile-displayed-pic-surrounding-div"
                        style={{
                            width: "100%"
                        }}
                        onClick={() => params.setRenderMobileDisplayer(false)}
                    />
                </div>
            </div>
        </div>
    )
}

export default TheBoat