import EventCreator from "./eventcreator"
import downArrow from "../icons/down-arrow.svg"

const { useState, useEffect } = require("react")
const today = new Date()

function Admin(params) {

    let i = -1

    const [password, setPassword] = useState("")
    const [passwordError, setPasswordError] = useState(false)

    //////////////////////////////////
    // TEST !!! to change !!!
    //////////////////////////////////
    const [serverOk, setServerOk] = useState(false)
    ///////////////////////////////////////////////////////


    const [action, setAction] = useState("create")
    const [eventList, setEventList] = useState([])
    const [modifying, setModifying] = useState("")
    const [deleted, setDeleted] = useState(false)

    //////////////////////////////////////////////////
    //              booking queries
    //////////////////////////////////////////////////
    const [eventNameQuery, setEventNameQuery] = useState(false)
    const [yearQuery, setYearQuery] = useState(today.getFullYear())
    const [monthQuery, setMonthQuery] = useState(false)
    const [dayQuery, setDayQuery] = useState(false)

    const [actualQuery, setActualQuery] = useState(null)

    const [displayMonthQuery, setDisplayMonthQuery] = useState(false)
    const [displayDayQuery, setDisplayDayQuery] = useState(false)
    const [displayEventNameQuery, setDisplayEventNameQuery] = useState(false)
    const [confirmSuppress, setConfirmSuppress] = useState(false)

    //////////////////////////////////////////////////
    //              Pics
    //////////////////////////////////////////////////
    const [picList, setPicList] = useState([])
    console.log("picList :")
    console.log(picList)
    const [picListConfig, setPicListConfig] = useState([])
    console.log("picListConfig :")
    console.log(picListConfig)
    const [listToDisplay, setListToDisplay] = useState([])
    console.log("list to display")
    console.log(listToDisplay)
    //////////////////////////////////////////////////
    //              Other
    //////////////////////////////////////////////////

    const [help, setHelp] = useState(false)
    const [filters, setFilters] = useState({
        eventName: true,
        numberOfGuest: true,
        numberOfKid: true,
        disabledPerson: true,
        price: true,
        name: true,
        firstName: true,
        address: true,
        address2: true,
        enterprise: true,
        country: true,
        city: true,
        zipCode: true,
        phone: true,
        email: true,
        date: true,
        year: true,
        month: true,
        day: true,
        bookingCode: true,
        vipOption: true,
        menu: true,
        option: true
    })

    const [displayFilters, setDisplayFilter] = useState(false)
    const [suppressContactPopup, setSuppressContactPopup] = useState(false)

    const [listOrder, setListOrder] = useState("")

    console.log("list order ***")
    console.log(listOrder)

    const filterList = []
    Object.entries(filters).forEach(([k, v]) => {
        filterList.push(k)
    })

    console.log("actual query")
    console.log(actualQuery)
    // console.log("****** QUERIES ******")
    // console.log(eventNameQuery)
    // console.log(yearQuery)
    // console.log(monthQuery)
    // console.log(dayQuery)

    useEffect(() => {
        if (serverOk) {
            getEventList()
        }
    }, [serverOk])

    function changeAllFilters(bool) {
        const newF = {}
        filterList.forEach(f => {
            newF[f] = bool
        })
        setFilters(newF)
        setDisplayFilter(false)
    }

    function postPassword() {
        fetch(`https://boreasapi.cyrilmorin.fr:${params.servPort}/checkpassword`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                password: password
            })
        })
            .then(res => {
                return res.json();
            })
            .then(data => {
                if (data.response === "ok") {
                    setPasswordError(false)
                    setServerOk(true)
                } else {
                    setPasswordError(true)
                }
            })
    }

    function getEventList() {
        fetch(`https://boreasapi.cyrilmorin.fr:${params.servPort}/geteventlist`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                password: password
            })
        })
            .then(res => {
                return res.json();
            })
            .then(data => {
                const modifiedData = data.reduce((acc, e) => {
                    const tmp = e.dates.map(date => {
                        return (new Date(date))
                    });
                    e.dates = tmp
                    acc.push(e)
                    return acc
                }, [])
                setEventList(modifiedData)
            })
    }

    function postPicList() {
        fetch(`https://boreasapi.cyrilmorin.fr:${params.servPort}/postpiclist`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                password: password,
                list: listToDisplay
            })
        })
            .then(res => {
                return res.json();
            })
            .then(data => {
                if (data.response === "ok") {
                    console.log("post pic list OK")
                    getPics()
                } else {
                    console.log("post pic list NOT OK !!!")
                }
            })
    }

    function getContactForms() {
        fetch(`https://boreasapi.cyrilmorin.fr:${params.servPort}/getcontactforms`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                password: password,
            })
        })
            .then(res => {
                return res.json();
            })
            .then(data => {
                setActualQuery(data)
            })
    }

    function getPics() {
        fetch(`https://boreasapi.cyrilmorin.fr:${params.servPort}/getboreaspiclist`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                return res.json();
            })
            .then(data => {
                let picListFromServ = data.filter(d => (d.name !== "." && d.name !== ".."))
                setPicList(picListFromServ)
            })
        fetch(`https://boreasapi.cyrilmorin.fr:${params.servPort}/getboreaspiclistconfig`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                return res.json();
            })
            .then(data => {
                setPicListConfig(data)
                console.log("pic list cfg from serv")
                console.log(data)
            })
    }

    function searchQuery() {
        const queryObject = { year: yearQuery }
        if (eventNameQuery) {
            queryObject["eventName"] = eventNameQuery
        }
        if (!monthQuery) {
            fetch(`https://boreasapi.cyrilmorin.fr:${params.servPort}/searchquerynumber`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    password: password,
                    queryObject: queryObject
                })
            })
                .then(res => {
                    return res.json();
                })
                .then(data => {
                    setActualQuery(data)
                })
        } else {
            queryObject["month"] = monthQuery
            if (dayQuery) {
                queryObject["day"] = dayQuery
            }
            fetch(`https://boreasapi.cyrilmorin.fr:${params.servPort}/searchqueryfull`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    password: password,
                    queryObject: queryObject
                })
            })
                .then(res => {
                    return res.json();
                })
                .then(data => {
                    setActualQuery(data)
                })
        }

    }

    function deleteEvent(title) {
        fetch(`https://boreasapi.cyrilmorin.fr:${params.servPort}/deleteevent`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                password: password,
                title: title
            })
        })
            .then(res => {
                return res.json();
            })
            .then(data => {
                if (data.response === "deleted") {
                    setDeleted(true)
                }
            })
    }

    function deleteContactForm() {
        fetch(`https://boreasapi.cyrilmorin.fr:${params.servPort}/deletecontactform`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                password: password,
                idToDelete: suppressContactPopup
            })
        })
            .then(res => {
                return res.json();
            })
            .then(data => {
                if (data.response === "deleted") {
                    console.log("form deleted")
                }
            })
        setSuppressContactPopup(false)
        getContactForms()
    }

    function handleOkPopup() {
        setDeleted(false)
        getEventList()
    }

    function showList() {
        let newLst = []
        if (picListConfig) {
            picListConfig.forEach(pic => {
                let found = picList.find(e => e.name === pic.name)
                if (found) {
                    newLst.push(pic)
                }
            })
        }
        if (picList) {
            picList.forEach(pic => {
                let found = picListConfig.find(e => e.name === pic.name)
                if (!found) {
                    newLst.push({
                        name: pic.name,
                        isDisplayed: true
                    })
                }
            })
        }
        setListToDisplay(newLst)
    }

    if (!serverOk) {
        return (
            <div id="auth-div-wrapper">
                <p
                    style={{
                        margin: 0
                    }}
                >Admin Password :</p>
                <input
                    style={{
                        marginBottom: "3vh"
                    }}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                ></input>
                {passwordError && <p>Le password est incorrect</p>}
                <button
                    onClick={postPassword}
                >Enter</button>
            </div>
        )
    } else {
        return (
            <div id="auth-div-wrapper">
                {
                    confirmSuppress &&
                    <div id="confirm-suppress">
                    <p>Confirmer la suppression de l'event ?</p>
                    <div div id="confirm-suppress-buttons">
                        <button
                        style={{
                            backgroundColor: "red",
                            marginRight: "10px",
                            marginLeft: "10px",
                            border: "none",
                            cursor: "pointer"
                        }}
                        onClick={() => {
                            deleteEvent(confirmSuppress)
                            setConfirmSuppress(null)
                        }}
                        >Oui</button>
                        <button
                        style={{
                            backgroundColor: "green",
                            border: "none",
                            cursor: "pointer"
                        }}
                        onClick={() => setConfirmSuppress(null)}
                        >Non</button>
                    </div>
                </div>}
                {
                    deleted &&
                    <div
                        style={{
                            position: "fixed",
                            left: 0,
                            top: 0,
                            height: "100vh",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "darkgreen",
                            zIndex: 10
                        }}
                    >
                        <p>Event supprimé</p>
                        <button
                            onClick={() => handleOkPopup()}
                        >OK !</button>
                    </div>
                }
                <div id="create-modify"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "3vh",
                        height: "5vh"
                    }}
                >
                    <button
                        onClick={() => setAction("create")}
                        style={{
                            backgroundColor: action === "create" ? "lightgreen" : "white"
                        }}
                    >Créer un event</button>
                    <button
                        onClick={() => setAction("modify")}
                        style={{
                            backgroundColor: action === "modify" ? "lightgreen" : "white"
                        }}
                    >Consulter / Modifier</button>
                    <button
                        onClick={() => {
                            setAction("bookings")
                            setActualQuery([])
                        }}
                        style={{
                            backgroundColor: action === "bookings" ? "lightgreen" : "white"
                        }}
                    >Réservations</button>
                    <button
                        onClick={() => {
                            setAction("contact")
                            getContactForms()
                        }}
                        style={{
                            backgroundColor: action === "contact" ? "lightgreen" : "white"
                        }}
                    >Contact</button>
                    <button
                        onClick={() => {
                            setAction("pics")
                            getPics()
                        }}
                        style={{
                            backgroundColor: action === "pics" ? "lightgreen" : "white"
                        }}
                    >Photos</button>
                </div>
                {
                    action === "create" &&
                    <EventCreator
                        title={""}
                        subtitle={""}
                        mainText={""}
                        informations={""}
                        stopover={""}
                        stopoverName={""}
                        stopoverLink={""}
                        picture={""}
                        leavingAt={""}
                        returningAt={""}
                        navigationDuration={""}
                        dates={[]}
                        month={"RECURRENT"}
                        price={0}
                        kidPrice={0}
                        vipAvailable={false}
                        vipText={""}
                        vipPrice={""}
                        vipKidPrice={""}
                        isActive={true}
                        stared={false}
                        language={params.language}
                        password={password}
                        getEventList={getEventList}
                        allowTitleChange={true}
                        servPort={params.servPort}
                        menu1Title={""}
                        menu1Link={""}
                        menu2Title={""}
                        menu2Link={""}
                        kidMenuLink={""}
                        option1={""}
                        option1Description={""}
                        option1Price={0}
                        option1Detail={{
                            limitAvailable: 0,
                            limitPerPersonOrGroup: 0,
                            limitPerPersonOrGroupType: "person",
                            multiplyByPeople: false
                        }}
                        option2={""}
                        option2Description={""}
                        option2Price={0}
                        option2Detail={{
                            limitAvailable: 0,
                            limitPerPersonOrGroup: 0,
                            limitPerPersonOrGroupType: "person",
                            multiplyByPeople: false
                        }}
                        pairBooking={false}
                        maximumCapacity={0}
                        showPlacesLeftFrom={0}
                    />
                }
                {
                    action === "modify" &&
                    <div
                    >
                        {
                            eventList.map(event => {
                                return (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            border: "solid 5px orange",
                                            marginTop: "2vh",
                                            marginBottom: "2vh"
                                        }}
                                        key={event.title}>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <p
                                                style={{
                                                    fontSize: "calc(2vh + 2vw)",
                                                    lineHeight: "10px",
                                                    color: "lightgreen",
                                                    fontWeight: "bold"
                                                }}
                                            >{event.title}</p>
                                            {
                                                event.isActive &&
                                                <p>L'événement est actif</p>
                                            }
                                            {
                                                event.stared &&
                                                <p>L'événement est à la une</p>
                                            }
                                            <button
                                                style={{
                                                    fontSize: "calc(1vh + 1vw)",
                                                    lineHeight: "10px",
                                                    fontWeight: "bold",
                                                    backgroundColor: "red",
                                                    marginBottom: "1vh",
                                                    padding: "10px"
                                                }}
                                                onClick={() => setConfirmSuppress(event.title)}
                                            >Supprimer</button>
                                            <button
                                                style={{
                                                    fontSize: "calc(1vh + 1vw)",
                                                    lineHeight: "10px",
                                                    fontWeight: "bold",
                                                    backgroundColor: "lightgreen",
                                                    marginBottom: "1vh",
                                                    padding: "10px"
                                                }}
                                                onClick={() => setModifying(event.title)}
                                            >Modifier</button>
                                        </div>
                                        {
                                            modifying === event.title &&
                                            <EventCreator
                                                title={event.title}
                                                subtitle={event.subtitle}
                                                mainText={event.mainText}
                                                informations={event.informations}
                                                stopover={event.stopover}
                                                stopoverName={event.stopoverName}
                                                stopoverLink={event.stopoverLink}
                                                picture={event.picture}
                                                leavingAt={event.leavingAt}
                                                returningAt={event.returningAt}
                                                navigationDuration={event.navigationDuration}
                                                dates={event.dates}
                                                month={event.month}
                                                price={event.price}
                                                kidPrice={event.kidPrice}
                                                vipAvailable={event.vipAvailable}
                                                vipText={event.vipText}
                                                vipPrice={event.vipPrice}
                                                vipKidPrice={event.vipKidPrice}
                                                isActive={event.isActive}
                                                stared={event.stared}
                                                language={params.language}
                                                password={password}
                                                getEventList={getEventList}
                                                allowTitleChange={false}
                                                servPort={params.servPort}
                                                menu1Title={event.menu1Title}
                                                menu1Link={event.menu1Link}
                                                menu2Title={event.menu2Title}
                                                menu2Link={event.menu2Link}
                                                kidMenuLink={event.kidMenuLink}
                                                option1={event.option1}
                                                option1Description={event.option1Description}
                                                option1Price={event.option1Price}
                                                option1Detail={event.option1Detail}
                                                option2={event.option2}
                                                option2Description={event.option2Description}
                                                option2Price={event.option2Price}
                                                option2Detail={event.option2Detail}
                                                pairBooking={event.pairBooking}
                                                maximumCapacity={event.maximumCapacity}
                                                showPlacesLeftFrom={event.showPlacesLeftFrom}
                                            />
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                }
                {
                    action === "bookings" &&
                    <div
                        style={{
                            width: "80%",
                            height: "fit-content",
                            border: "solid 2px red",
                            marginTop: "2vh"
                        }}
                    >
                        <div id="search-section"
                            style={{
                                backgroundColor: "rgb(48, 59, 59)",
                                display: "flex",
                                flexWrap: "wrap"
                            }}
                        >
                            <p
                                style={{
                                    fontSize: "calc(1.3vh + 1.3vw)",
                                    width: "100%",
                                    textAlign: "center",
                                    margin: 0
                                }}
                            >Recherche :</p>
                            <div className="search-div">
                                <p>Afficher l'aide ?</p>
                                <input
                                    type="checkbox"
                                    defaultChecked={help}
                                    onChange={() => setHelp(!help)}
                                />
                            </div>

                            {
                                help &&
                                <div>
                                    <p
                                        style={{
                                            fontSize: "calc(1.2vh + 1.2vw)"
                                        }}
                                    >Le champs année est obligatoire, s'il est seul selectionné, il renverra
                                        le nombre total de réservations sur l'année sans plus de détails.
                                    </p>
                                    <p
                                        style={{
                                            fontSize: "calc(1.2vh + 1.2vw)"
                                        }}
                                    >Si un autre champs est séléctionné afin d'affiner la recherche,
                                        les détails de chaque résa seront affichés pour le mois ou le jour séléctionné
                                    </p>
                                </div>
                            }
                            <div className="search-div">
                                <p>Année :</p>
                                <input
                                    type="number"
                                    value={yearQuery}
                                    onChange={(e) => setYearQuery(e.target.value)}
                                />
                            </div>
                            <div className="search-div">
                                <p>Mois :</p>
                                <input
                                    type="checkbox"
                                    defaultChecked={displayMonthQuery}
                                    onChange={() => {
                                        setMonthQuery(() => {
                                            return displayMonthQuery ? false : today.getMonth() + 1
                                        })
                                        setDayQuery(false)
                                        setDisplayDayQuery(false)
                                        setDisplayMonthQuery(!displayMonthQuery)
                                    }}
                                />
                                {
                                    displayMonthQuery &&
                                    <input
                                        type="number"
                                        min="1"
                                        max="12"
                                        value={monthQuery}
                                        onChange={(e) => setMonthQuery(e.target.value)}
                                    />
                                }
                            </div>
                            {
                                displayMonthQuery &&
                                <div className="search-div">
                                    <p>Jour :</p>
                                    <input
                                        type="checkbox"
                                        defaultChecked={displayDayQuery}
                                        onChange={() => {
                                            setDayQuery(() => {
                                                return displayDayQuery ? false : today.getDate()
                                            })
                                            setDisplayDayQuery(!displayDayQuery)
                                        }}
                                    />
                                    {
                                        (displayDayQuery && monthQuery !== "") &&
                                        <input
                                            type="number"
                                            min="1"
                                            max="31"
                                            value={dayQuery}
                                            onChange={(e) => setDayQuery(e.target.value)}
                                        />
                                    }
                                </div>}
                            <div className="search-div">
                                <p>Nom de l'event :</p>
                                <input
                                    type="checkbox"
                                    defaultChecked={displayEventNameQuery}
                                    onChange={() => {
                                        setEventNameQuery(() => {
                                            return displayEventNameQuery ? false : "nom de l'event"
                                        })
                                        setDisplayEventNameQuery(!displayEventNameQuery)
                                    }}
                                />
                                {
                                    displayEventNameQuery &&
                                    <input
                                        value={eventNameQuery}
                                        onChange={(e) => setEventNameQuery(e.target.value)}
                                    />
                                }
                            </div>
                            <button
                                onClick={() => searchQuery()}
                                style={{
                                    backgroundColor: "green",
                                    color: "black",
                                    border: "solid yellow 2px",
                                    width: "100%",
                                    height: "4vh",
                                    marginTop: "2vh"
                                }}
                            >Rechercher</button>
                        </div>
                        <div id="query-filters"
                            style={{
                                backgroundColor: "rgb(48, 49, 59)",
                                display: "flex",
                                flexWrap: "wrap"
                            }}
                        >
                            <p
                                style={{
                                    fontSize: "calc(1.3vh + 1.3vw)",
                                    width: "100%",
                                    textAlign: "center",
                                    margin: 0
                                }}
                            >Filtres :</p>
                            <button
                                style={{
                                    width: "30%",
                                    marginLeft: "3%"
                                }}
                                onClick={() => changeAllFilters(true)}
                            >Afficher tous les champs</button>
                            <button
                                style={{
                                    width: "30%",
                                    marginLeft: "3%"
                                }}
                                onClick={() => changeAllFilters(false)}
                            >N'afficher aucun champs</button>
                            <button
                                style={{
                                    width: "30%",
                                    marginLeft: "3%"
                                }}
                                onClick={() => setDisplayFilter(true)}
                            >Filtres</button>
                            {
                                displayFilters &&
                                filterList.map(f => {
                                    return (
                                        <div
                                            key={`${f.toString()}filter`}
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginLeft: "10px",
                                                marginTop: "1vh"
                                            }}
                                        >
                                            <p
                                                style={{
                                                    margin: 0,
                                                    padding: 0
                                                }}
                                            >{f}</p>
                                            <input
                                                type="checkbox"
                                                defaultChecked={filters[f]}
                                                onChange={() => setFilters(() => {
                                                    const prev = { ...filters }
                                                    prev[f] = !prev[f]
                                                    return prev
                                                })}
                                            ></input>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div>
                            {
                                typeof actualQuery === "number" &&
                                <div>
                                    <p>Total : {actualQuery}</p>
                                    {
                                        eventNameQuery &&
                                        <p>pour la presta : {eventNameQuery}</p>
                                    }
                                    <p>sur l'année : {yearQuery}</p>
                                </div>
                            }
                            {
                                (typeof actualQuery !== "number" && actualQuery) &&
                                <div>
                                    <p>Total de groupes : {actualQuery.length}</p>
                                    <p>Total de résas : {actualQuery.reduce((acc, b) => {
                                        acc += (b.numberOfGuest + b.numberOfKid)
                                        return acc
                                    }, 0)}</p>
                                    {
                                        actualQuery.length > 0 &&
                                        <p>{actualQuery[0].menu}</p>
                                    }
                                    {
                                        actualQuery.map(b => {
                                            i++
                                            const arr = []
                                            Object.entries(b).forEach(([k, v]) => {
                                                if (filters[k]) {
                                                    arr.push(<p
                                                        style={{
                                                            margin: 0
                                                        }}
                                                    >{k} : {v}</p>)
                                                }
                                            });
                                            return (
                                                <div
                                                    key={b._id}
                                                    style={{
                                                        border: "rgba(242, 135, 5, 0.479) 1px solid",
                                                        backgroundColor: i % 2 === 0 ? "rgb(40, 40, 40)" : "rgb(15, 15, 15)",
                                                        marginTop: "1vh"
                                                    }}
                                                >
                                                    {arr}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                        </div>
                    </div>
                }
                {
                    action === "contact" &&
                    <div>
                        {
                            suppressContactPopup &&
                            <div
                                style={{
                                    zIndex: 20,
                                    height: "100vh",
                                    width: "100%",
                                    backgroundColor: "black",
                                    position: "fixed",
                                    top: 0,
                                    left: 0,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-around",
                                    fontSize: "calc(1.3vh + 1.3vw)"
                                }}
                            >
                                <p>Voulez vous vraiment supprimez cette demande ?</p>
                                <button
                                    style={{
                                        fontSize: "calc(1.3vh + 1.3vw)",
                                        backgroundColor: "red",
                                        border: "2px solid darkgrey"
                                    }}
                                    onClick={() => deleteContactForm(suppressContactPopup)}
                                >Oui</button>
                                <button
                                    style={{
                                        fontSize: "calc(1.3vh + 1.3vw)",
                                        backgroundColor: "green",
                                        border: "2px solid darkgrey"
                                    }}
                                    onClick={() => setSuppressContactPopup(false)}
                                >Non</button>
                            </div>
                        }
                        {
                            actualQuery &&
                            actualQuery.map(b => {
                                i++
                                const arr = []
                                Object.entries(b).forEach(([k, v]) => {
                                    arr.push(<p
                                        style={{
                                            margin: 0
                                        }}
                                    >{k} : {v}</p>)
                                });
                                arr.push(
                                    <button
                                        style={{
                                            height: "40px",
                                            width: "80px",
                                            textAlign: "center",
                                            backgroundColor: "red",
                                            border: "2px solid black",
                                            marginLeft: "300px"
                                        }}
                                        onClick={() => setSuppressContactPopup(b._id)}
                                    >supprimer</button>
                                )
                                return (
                                    <div
                                        key={b._id}
                                        style={{
                                            border: "rgba(242, 135, 5, 0.479) 1px solid",
                                            backgroundColor: i % 2 === 0 ? "rgb(40, 40, 40)" : "rgb(15, 15, 15)",
                                            marginTop: "1vh"
                                        }}
                                    >
                                        {arr}
                                    </div>
                                )
                            })
                        }
                    </div>
                }
                {
                    action === "pics" &&
                    <div
                        style={{
                            width: "90%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            marginTop: "60px"
                        }}
                    >
                        <button
                            onClick={() => showList()}
                            style={{
                                marginBottom: "20px"
                            }}
                        >Afficher la liste des photos</button>
                        <div
                            style={{
                                width: "100%",
                                height: "fit-content",
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                overflowY: "hidden",
                                marginBottom: "20px"
                            }}>
                            {
                                listToDisplay.map((pic, i) => {

                                    return (
                                        <div
                                            key={pic.name}
                                            style={{
                                                height: "450px",
                                                width: "fit-content",
                                                display: "flex",
                                                flexDirection: "column",
                                                borderRight: "black solid 2px",
                                                borderLeft: "black solid 2px",
                                                margin: 0,
                                                padding: 0,
                                                position: "relative"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    height: "80%",
                                                    display: "flex",
                                                    justifyContent: "center"
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        height: "100%",
                                                        margin: 0,
                                                        padding: 0,
                                                        position: "relative"
                                                    }}
                                                    alt={pic.name}
                                                    src={`http://pics.boreas.paris/leboreas/${pic.name}`} />
                                            </div>
                                            <div
                                                style={{
                                                    height: "20%",
                                                    width: "100%",
                                                    minWidth: "350px",
                                                    backgroundColor: "black",
                                                    padding: 0,
                                                    margin: 0,
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    flexDirection: "row",
                                                    flexWrap: "wrap",
                                                    position: "relative"
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        height: "50%",
                                                        width: "65%",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        position: "relative",
                                                        justifyContent: "center",
                                                        backgroundColor: "darkgray"
                                                    }}
                                                >
                                                    <p>position <span
                                                        style={{
                                                            color: "red"
                                                        }}
                                                    >{i}</span> (0 - {listToDisplay.length - 1}) :</p>
                                                    <input
                                                        style={{
                                                            margin: 0,
                                                            padding: 0,
                                                            border: "none",
                                                            height: "60%",
                                                            width: "30px",
                                                            position: "relative"
                                                        }}
                                                        value={listOrder}
                                                        onChange={e => setListOrder(parseInt(e.target.value, 10))}
                                                    />
                                                    {
                                                        typeof (listOrder) === "number" &&
                                                        <button
                                                            style={{
                                                                margin: 0,
                                                                padding: 0,
                                                                height: "60%",
                                                                position: "relative",
                                                                backgroundColor: "green",
                                                                border: "black solid 1px"
                                                            }}
                                                            onClick={() => {
                                                                console.log("order button clicked")
                                                                console.log(typeof (listOrder))
                                                                if (typeof (listOrder) === "number" && listOrder >= 0 && listOrder < listToDisplay.length) {
                                                                    // let tmp = listToDisplay[i]
                                                                    // let tmpList = [...listToDisplay]
                                                                    // tmpList[i] = tmpList[listOrder]
                                                                    // tmpList[listOrder] = tmp
                                                                    let tmp = listToDisplay
                                                                    let item = tmp.splice(i, 1)
                                                                    tmp.splice(listOrder, 0, item[0])
                                                                    setListToDisplay(tmp)
                                                                    setListOrder("")
                                                                }
                                                            }}
                                                        >OK</button>
                                                    }
                                                </div>
                                                <div
                                                    style={{
                                                        height: "50%",
                                                        width: "35%",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        position: "relative",
                                                        justifyContent: "center",
                                                        backgroundColor: "rgb(31, 31, 31)"
                                                    }}
                                                >
                                                    <p>Affichée ? :</p>
                                                    <input
                                                        style={{
                                                            margin: 0,
                                                            padding: 0,
                                                            border: "none",
                                                            height: "60%",
                                                            width: "30px",
                                                            position: "relative"
                                                        }}
                                                        type="checkbox"
                                                        defaultChecked={pic.isDisplayed}
                                                        onChange={() => {
                                                            let tmp = [...listToDisplay]
                                                            tmp[i].isDisplayed = !tmp[i].isDisplayed
                                                            setListToDisplay(tmp)
                                                        }}
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        height: "50%",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        justifyContent: "space-around",
                                                        backgroundColor: "darkblue",
                                                    }}
                                                >
                                                    <img
                                                        style={{
                                                            height: "40px",
                                                            transform: "rotateZ(90deg)"
                                                        }}
                                                        alt="arrow-left"
                                                        src={downArrow}
                                                        onClick={() => {
                                                            if (i > 0) {
                                                                let tmp = listToDisplay[i]
                                                                let tmpList = [...listToDisplay]
                                                                tmpList[i] = tmpList[i - 1]
                                                                tmpList[i - 1] = tmp
                                                                setListToDisplay(tmpList)
                                                            }
                                                        }}
                                                    />
                                                    <img
                                                        style={{
                                                            height: "40px",
                                                            transform: "rotateZ(270deg)"
                                                        }}
                                                        alt="arrow-right"
                                                        src={downArrow}
                                                        onClick={() => {
                                                            if (i < listToDisplay.length - 1) {
                                                                let tmp = listToDisplay[i]
                                                                let tmpList = [...listToDisplay]
                                                                tmpList[i] = tmpList[i + 1]
                                                                tmpList[i + 1] = tmp
                                                                setListToDisplay(tmpList)
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <button
                            onClick={() => postPicList()}
                        >Enregistrer sur le serveur</button>
                    </div>
                }
            </div>
        )
    }
}

export default Admin