import workInProgress from "../icons/work-in-progress.svg"

function WarningPopup(params) {
    

    return(
        <div id="warning-popup">
            <img id="warning-img" src={workInProgress}/>
            <p className="warning-text">Bienvenue sur le nouveau site du Boreas !</p>
            <p className="warning-text">Ce site est actuellement en construction, ce qui implique que toutes les fonctionnalités ne sont pas encore disponibles</p>
            <p className="warning-text">N'hésitez pas à revenir nous voir souvent, nous apportons des mises à jour régulières au site</p>
            <p className="warning-text">Pour toute demande de renseignements, notamment relatives aux réservations ou aux privatisation, ou pour signaler un problème avec le site, n'hésitez pas à contacter l'équipe:&nbsp;
             <a 
             style={{
                 color: "rgb(242, 135, 5)"
             }}
             href="mailto: zara@boreas.paris">zara@boreas.paris</a>
            </p>
            <p className="warning-text">Vous pouvez cliquer n'importe où sur ce message pour le fermer</p>
        </div>
    )
}

export default WarningPopup