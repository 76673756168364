import { Children, useEffect, useState } from "react"
import adultLogo from "../icons/adult.png"
import childrenLogo from "../icons/children.png"
import disabledLogo from "../icons/disabled.png"
import plus from "../icons/plus.png"
import minus from "../icons/minus.png"

function WhoIsIn(params) {

    const [modifying, setModifying] = useState(true)
    const [scrollPos, setScrollPos] = useState(0)

    const [adults, setAdults] = useState(params.numberOfGuests)
    const [children, setChildren] = useState(params.numberOfKid)
    const [disabledPerson, setDisabledPerson] = useState(params.disabledPerson)
    const [justMounted, setJustMounted] = useState(true)

    console.log("limit booking", params.limitBooking)

    function handleScroll() {
        if (window.scrollY > scrollPos + 150 && ((params.isMobile && window.scrollY > 800) || !params.isMobile && window.scrollY > 550)) {
            params.setNumberOfGuests(adults)
            params.setNumberOfKid(children)
            params.setDisabledPerson(disabledPerson)
            if (!justMounted) {
                setModifying(false)
            }
        }
    }

    useEffect(() => {
        if (justMounted) {
            setTimeout(() => {
                setJustMounted(false)
            }, 1000);
        }
    }, [])

    useEffect(() => {
        window.addEventListener("scroll", handleScroll)
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [adults, children, disabledPerson, scrollPos, justMounted])

    if (!modifying) {
        return (
            <div id="who-is-in"
                style={{
                    cursor: "pointer",
                    width: params.isMobile ? "250px" : params.pairBooking ? "300px" : "400px"
                }}
                onClick={() => {
                    setModifying(true)
                    setScrollPos(window.scrollY)
                }}
            >
                <img src={adultLogo} className="who-is-in-icon" />
                <div className="who-is-in-nm"
                >
                    <p className="who-is-in-text">{adults}</p>
                    <p className="who-is-in-text">Adulte{adults > 1 ? "s" : ""}</p>
                </div>
                {!params.pairBooking && <div className="who-is-in-separation-bar" />}
                {!params.pairBooking && <img src={childrenLogo} className="who-is-in-icon" />}
                {!params.pairBooking && <div className="who-is-in-nm"
                >
                    <p className="who-is-in-text">{children}</p>
                    <p className="who-is-in-text">Enfant{children > 1 ? "s" : ""}</p>
                </div>}
                <div className="who-is-in-separation-bar" />
                <img src={disabledLogo} className="who-is-in-icon" />
                <div className="who-is-in-nm"
                >
                    <p className="who-is-in-text">PMR ?</p>
                    <p className="who-is-in-text">{disabledPerson ? "Oui" : "Non"}</p>
                </div>
            </div>
        )
    } else {
        return (
            <div id="who-is-in-mod"
            style={{
                width: params.isMobile ? "270px" : params.pairBooking ? "400px" : "550px"
            }}
            >
                <div className="who-is-in-m">
                    <img src={adultLogo} className="who-is-in-icon-m" />
                    <p className="who-is-in-text">Adulte{adults > 1 ? "s" : ""}</p>
                    <p className="who-is-in-text">(12 ans et +)</p>
                    <div className="who-is-in-plus-minus">
                        <img src={minus} className="plus-minus-icon"
                            style={{
                                filter: (adults === 1 || adults === params.adultsMin) ?
                                    "invert(75%)" : "invert(100%)"
                            }}
                            onClick={() => {
                                if (adults === 1 || adults === 0 || adults === params.adultsMin) {
                                    return
                                } else if (!params.pairBooking) {
                                    setAdults(adults - 1)
                                } else {
                                    setAdults(adults - 2)
                                }
                            }}
                        />
                        <p className="who-is-in-num">{adults}</p>
                        <img src={plus} className="plus-minus-icon"
                            onClick={() => {
                                if (adults + children < params.limitBooking) {
                                    if (!params.pairBooking) {
                                        setAdults(adults + 1)
                                    } else {
                                        setAdults(adults + 2)
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
                {!params.pairBooking && <div className="who-is-in-separation-bar-m" />}
                {!params.pairBooking && <div className="who-is-in-m">
                    <img src={childrenLogo} className="who-is-in-icon-m" />
                    <p className="who-is-in-text">Enfant{children > 1 ? "s" : ""}</p>
                    <p className="who-is-in-text">&nbsp;</p>
                    <div className="who-is-in-plus-minus">
                        <img src={minus} className="plus-minus-icon"
                            style={{
                                filter: (children === 0 || children === params.childrenMin) ?
                                    "invert(75%)" : "invert(100%)"
                            }}
                            onClick={() => {
                                if (children === 0 || children === params.childrenMin) {
                                    return
                                } else {
                                    setChildren(children - 1)
                                }
                            }}
                        />
                        <p className="who-is-in-num">{children}</p>
                        <img src={plus} className="plus-minus-icon"
                            onClick={() => {
                                if (adults + children <= params.limitBooking) {
                                    setChildren(children + 1)
                                }
                            }}
                        />
                    </div>
                </div>}
                {
                    !params.isMobile &&
                    <div className="who-is-in-separation-bar-m" />
                }
                <div className="who-is-in-m">
                    <img src={disabledLogo} className="who-is-in-icon" />
                    <p
                        style={{
                            marginTop: "5px"
                        }}
                        className="who-is-in-text">Personne à mobilité</p>
                    <p className="who-is-in-text">réduite</p>
                    <div id="disabled-btn-div"
                        onClick={() => setDisabledPerson(!disabledPerson)}
                        style={{
                            backgroundColor: disabledPerson ? "green" : "gray"
                        }}
                    >
                        <div id="disabled-btn"
                            style={{
                                marginLeft: disabledPerson ? "auto" : 0
                            }}
                        ></div>
                    </div>
                </div>
                <div id="who-is-in-validation-div">
                    <button id="who-is-in-validation-btn"
                        onClick={() => {
                            params.setNumberOfGuests(adults)
                            params.setNumberOfKid(children)
                            params.setDisabledPerson(disabledPerson)
                            setModifying(false)
                        }}
                    >VALIDER</button>
                </div>
            </div>
        )
    }
}

export default WhoIsIn