import { CSSTransition } from "react-transition-group"
import { Link } from "react-router-dom"
import { useState, useEffect } from "react"

function DisplayEvent(params) {

    const [amountBooked, setAmountBooked] = useState(0)

    const ev = params.eventToDisplay
    console.log("EV : ", ev)
    let actualDate = new Date()
    actualDate.setHours(0,0,0,0)
    let eventHasPassed = params.chosenDate < actualDate

    console.log("event has passed : ", eventHasPassed)

    useEffect(() => {
        if (!ev) {
            return
        }
        fetch(`https://boreasapi.cyrilmorin.fr:${params.servPort}/getbookingsamountperdate`, {
            method: "POST",
            body: JSON.stringify({
                year: params.chosenDate.getUTCFullYear(),
                month: params.chosenDate.getUTCMonth() + 1,
                day: params.chosenDate.getUTCDate(),
                eventName: ev.title
            }),
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                return res.json();
            })
            .then(data => {
                setAmountBooked(data.amount)
            });
    }, [params.chosenDate])

    console.log("amountbooked", amountBooked)
    console.log("maxamount", ev.maximumCapacity)
    console.log("showPlacesLeftFrom", ev.showPlacesLeftFrom)

    if (ev) {
        return (
            <CSSTransition
                mountOnEnter
                unmountOnExit
                in={!params.truncatedFlyer}
                timeout={500}
                classNames="flyer-transition"
            >
                <div id="main-event">
                    <div
                        onClick={() => params.handleFrameClick(`https://pics.boreas.paris/${ev.picture}`)}
                        id="picture-frame"
                        style={{
                            backgroundImage: `url("https://pics.boreas.paris/${ev.picture}")`,
                            cursor: "pointer"
                        }}
                    >
                        {/* <img src={pinkCruiseFlyer} id="picture-in-frame"
                                            onClick={() => handleFrameClick(pinkCruiseFlyer)}
                                        /> */}
                        <div id="picture-frame-shadow">
                            <p
                                id="click-to-grow"
                            >Cliquer pour agrandir le flyer</p>
                        </div>
                    </div>
                    <h3 id="main-event-title">{ev.title}</h3>
                    <h4 id="main-event-subtitle">{ev.subtitle}</h4>
                    <p className="main-event-text">{ev.mainText}</p>
                    <p className="event-informations">{ev.informations}</p>
                    {
                        ev.menu1Title &&
                        <a className="event-informations"
                            style={{
                                textDecoration: "underline"
                            }}
                            target="_blank"
                            href={`https://pics.boreas.paris/menus/${ev.menu1Link}`}
                        >{ev.menu1Title}</a>
                    }
                    {
                        ev.menu2Title &&
                        <a className="event-informations"
                            style={{
                                textDecoration: "underline"
                            }}
                            target="_blank"
                            href={`https://pics.boreas.paris/menus/${ev.menu2Link}`}
                        >{ev.menu2Title}</a>
                    }
                    <p className="stopOverName">Escale: <a
                        id="stopover-link"
                        style={{
                            color: "rgb(242, 135, 5)",
                            marginLeft: "10px",
                            textDecoration: "underline"
                        }}
                        target="_blank"
                        rel="noreferrer"
                        href={ev.stopoverLink}>{ev.stopoverName}</a></p>
                    <p className="stopOver">{ev.stopover}
                    </p>
                    <p id="date">Sélectionner une date:</p>
                    <select
                        style={{
                            color: "white",
                            backgroundColor: "black",
                            borderRadius: "8px",
                            width: "90%",
                            height: "50px",
                            border: "solid 1px rgb(242, 135, 5)",
                            marginLeft: "5%",
                            marginBottom: "3vh"
                        }}
                        onChange={(e) => params.setChosenDate(new Date(e.target.value))}
                    >
                        <option selected key={params.chosenDate} value={params.chosenDate}>{params.chosenDate.toLocaleDateString(params.language)}</option>
                        {
                            ev.dates.map(date => {
                                if (date.toLocaleDateString(params.language) !== params.chosenDate.toLocaleDateString(params.language)) {
                                    return (
                                        <option key={date} value={date}>{date.toLocaleDateString(params.language)}</option>
                                    )
                                }
                            })
                        }
                    </select>
                    {
                        ev.maximumCapacity - amountBooked <= ev.showPlacesLeftFrom &&
                        <p
                            className="event-informations"
                            style={{
                                color: "red"
                            }}
                        >Il reste {ev.maximumCapacity - amountBooked} places disponibles</p>
                    }
                    {
                        ev.maximumCapacity - amountBooked <= 0 &&
                        <button
                            style={{
                                cursor: 'pointer',
                                fontWeight: "bold"
                            }}
                            id="booking-button">COMPLET</button>
                    }
                    {
                        ev.maximumCapacity - amountBooked > 0 && !eventHasPassed &&
                        <Link to={`/booking?step=1`}

                        >
                            <button
                                style={{
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    setTimeout(() => {
                                        window.scrollTo({ top: 0, behavior: "smooth" })
                                    }, 50);
                                }}
                                id="booking-button">Réserver</button>
                        </Link>
                    }
                    {
                         ev.maximumCapacity - amountBooked > 0 && eventHasPassed &&
                         <button
                                style={{
                                    cursor: 'pointer',
                                    textTransform: "capitalize"
                                }}
                                id="booking-button">événement terminé</button>
                    }
                </div>
            </CSSTransition>
        )
    } else {
        return (
            <div>LOADING</div>
        )
    }
}

export default DisplayEvent