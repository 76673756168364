import { useEffect, useState } from "react"
import { useLocation } from "react-router";
import Header from "./header";
import hamburger from '../icons/menu.svg'
import Footer from "./footer";
import close from "../icons/close.svg"
import DisplayEvent from "./displayedevent";
import { CSSTransition } from "react-transition-group";
import FlyerDisplayer from "./flyerdisplayer";
import { HashLink } from "react-router-hash-link";
import { Link, useHistory } from "react-router-dom";

const pcStyleMany = {
    width: "30%",
    height: "500px",
    marginTop: "0vh",
    marginBottom: "5vh",
    borderRadius: "20px",
    marginLeft: "2.5%",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    border: "rgba(242, 135, 5, 0.479) 2px solid",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
}

const pcStyleTwo = {
    width: "40%",
    height: "700px",
    marginTop: "0vh",
    borderRadius: "20px",
    marginLeft: "6.66%",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    border: "rgba(242, 135, 5, 0.479) 2px solid",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
}

const pcStyleOne = {
    width: "48%",
    height: "800px",
    marginTop: "0vh",
    borderRadius: "20px",
    marginLeft: "26%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    border: "rgba(242, 135, 5, 0.479) 2px solid",
}

const pcTilteStyleMany = {
    margin: 0,
    padding: 0,
    lineHeight: "20px",
    fontSize: "calc(0.9vh + 0.9vw)",
    fontWeight: "bold",
    textDecoration: "underline",
}

const pcTitleStyleTwo = {
    margin: 0,
    padding: 0,
    lineHeight: "28px",
    fontSize: "calc(1.2vh + 1.2vw)",
    fontWeight: "bold",
    textDecoration: "underline"
}

const pcTitleStyleOne = {
    margin: 0,
    padding: 0,
    lineHeight: "34px",
    fontSize: "calc(1.5vh + 1.5vw)",
    fontWeight: "bold",
    textDecoration: "underline"
}

const pcMonthStyleMany = {
    margin: 0,
    marginTop: "1vh",
    padding: 0,
    lineHeight: "20px",
    fontSize: "calc(0.8vh + 0.8vw)",
}

const pcMonthStyleTwo = {
    margin: 0,
    marginTop: "1vh",
    padding: 0,
    lineHeight: "20px",
    fontSize: "calc(1vh + 1vw)",
}

const pcMonthStyleOne = {
    margin: 0,
    marginTop: "1vh",
    padding: 0,
    lineHeight: "20px",
    fontSize: "calc(1.2vh + 1.2vw)",
}
/****************************************************
 * 
 *          MOBILE STYLE                               
 * 
 * *************************************************/
const mobileStyleMany = {
    width: "40%",
    height: "300px",
    marginTop: "0vh",
    marginBottom: "3vh",
    border: "rgba(242, 135, 5, 0.479) 2px solid",
    borderRadius: "20px",
    cursor: "pointer",
    marginLeft: "6.66%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
}

const mobileStyleOne = {
    width: "60%",
    height: "450px",
    marginTop: "0vh",
    border: "rgba(242, 135, 5, 0.479) 2px solid",
    borderRadius: "20px",
    cursor: "pointer",
    marginLeft: "20%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
}

const mobileTitleMany = {
    margin: 0,
    padding: 0,
    lineHeight: "20px",
    fontSize: "calc(1vh + 1vw)",
    fontWeight: "bold",
    textDecoration: "underline"
}

const mobileTitleOne = {
    margin: 0,
    padding: 0,
    lineHeight: "20px",
    fontSize: "calc(1.5vh + 1.5vw)",
    fontWeight: "bold",
    textDecoration: "underline"
}

const mobileMonthMany = {
    margin: 0,
    padding: 0,
    lineHeight: "20px",
    fontSize: "calc(0.8vh + 0.8vw)",
}

const mobileMonthOne = {
    margin: 0,
    padding: 0,
    lineHeight: "20px",
    fontSize: "calc(1.1vh + 1.1vw)",
}

function Events(params) {

    const isMobile = (params.ratio.width < 600 || params.ratio.width < params.ratio.height) ? true : false
    const [scrollPos, setScrollPos] = useState(0)
    const [displayHeader, setDisplayHeader] = useState(true)
    const [truncatedFlyer, setTruncatedFlyer] = useState(false)
    const [flyerSrc, setFlyerSrc] = useState(null)
    const [displayMobileMenu, setDisplayMobileMenu] = useState(false)
    const [burgerOrClose, setBurgerOrClose] = useState(hamburger)
    const [stared, setStared] = useState(null)
    const [noStared, setNoStared] = useState(false)
    const [actualQuery, setActualQuery] = useState("")
    const [flyerQuery, setFlyerQuery] = useState(false)

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    let history = useHistory()

    let query = useQuery();
    let queryEvent = query.get("title")

    if (queryEvent !== actualQuery){
        console.log("queryevent ... :", queryEvent)
        setActualQuery(queryEvent)
    }

    let queryFlyer = query.get("displayflyer")
    console.log("queryflyer", queryFlyer)

    if (!queryFlyer){
        if (flyerQuery === true){
            setFlyerQuery(false)
        }        
    } else if (queryFlyer === "true"){
        if (flyerQuery === false){
            setFlyerQuery(true)
        }
    }

    function handleScroll() {
        if (window.scrollY > scrollPos) {
            if (window.scrollY > 50) {
                setDisplayHeader(false)
                setDisplayMobileMenu(false)
            }
            setScrollPos(window.scrollY)
        } else {
            setDisplayHeader(true)
            setScrollPos(window.scrollY)
        }
        // let bg = document.getElementById("main-bg")
        // if (window.scrollY < 1000 && window.scrollY > 200) {
        //     if (bg) {
        //         bg.style.filter = `brightness(120%) blur(${Math.floor((window.scrollY - 200) / 100)}px)`
        //     }
        // } else if (window.scrollY <= 200) {
        //     if (bg) {
        //         bg.style.filter = `brightness(120%) blur(0px)`
        //     }
        // }
    }

    function handleFrameClick(flyer) {
        setTruncatedFlyer(true)
        setFlyerSrc(flyer)
        setDisplayHeader(false)
        history.push({
            search: "?displayflyer=true"
        })
    }

    function HandleFlyerClick(params) {
        setTruncatedFlyer(false)
        setDisplayHeader(true)
    }

    useEffect(() => {
        if (flyerQuery === false){
            HandleFlyerClick()
        }
    }, [flyerQuery])

    useEffect(() => {
        setTimeout(() => {
            if (!stared) {
                setNoStared(true)
            }
        }, 500);
    }, [])

    useEffect(() => {
        if (params.activeEvents.length > 0) {
            let stared = params.activeEvents.filter(e => e.stared === true)
            setStared(stared[0])
        }
    }, [params.activeEvents])

    useEffect(() => {
        let qEvent = params.activeEvents.find(e => e.title === queryEvent)
        if (qEvent) {
            params.setSelected(qEvent)
        } else {
            params.setSelected(stared)
        }
    }, [stared, actualQuery])

    useEffect(() => {
        if (params.selected) {
            params.setChosenDate(params.selected.dates[0])
        }
    }, [params.selected])

    useEffect(() => {
        window.addEventListener("scroll", handleScroll)
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [scrollPos])

    useEffect(() => {
        if (displayMobileMenu) {
            setBurgerOrClose(close)
        } else {
            setBurgerOrClose(hamburger)
        }
    }, [displayMobileMenu])

    if (!params.selected || !stared) {
        return (
            <div id="events-main-wrapper">
                <div id="main-bg">
                </div>
                {
                    noStared &&
                    <div id="no-event-div">
                        <p id="no-event-text">Aucun événement n'est programmé pour le moment</p>
                        <Link to="/"><button
                            style={{
                                color: "white",
                                fontSize: "calc(1.2vh + 1.2vw)",
                                borderRadius: "8px",
                                padding: "10px",
                                backgroundColor: "black",
                                border: "solid 2px rgb(242, 135, 5)"
                            }}
                        >Retour</button></Link>
                    </div>
                }
            </div>
        )
    } else {
        return (
            <div id="events-main-wrapper">
                <div id="main-bg">

                </div>
                <CSSTransition
                    mountOnEnter
                    unmountOnExit
                    in={truncatedFlyer}
                    timeout={500}
                    classNames="flyer-transition"
                >
                    <FlyerDisplayer
                        style={{
                            zIndex: 100
                        }}
                        src={flyerSrc}
                        HandleFlyerClick={HandleFlyerClick}
                        setTruncatedFlyer={setTruncatedFlyer} />
                </CSSTransition>
                {
                    !isMobile &&
                    <Header
                        isMobile={isMobile}
                        displayHeader={displayHeader}
                    />
                }
                {/* <div
                style={{
                    height: "40vh",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                    backgroundColor: "gray"
                }}
                >
                    <Link to="events?title=tes+event+1"><p>tes ev</p></Link>
                    <Link to="events?title=2222+"><p>2222</p></Link>
                </div> */}
                {
                    !isMobile &&
                    <div id="events-wrapper">
                        <div id="events-list">
                            <div
                                style={params.activeEvents.length > 2 ?
                                    pcStyleMany : params.activeEvents.length === 2 ?
                                        pcStyleTwo : pcStyleOne}
                            >
                                <img
                                    src={`https://pics.boreas.paris/${stared.picture}`}
                                    onClick={() => params.setSelected(stared)}
                                    style={{
                                        borderRadius: "20px",
                                        height: "85%",
                                        width: "100%",
                                        border: "rgba(242, 135, 5, 0.479) 2px solid",
                                        cursor: "pointer"
                                    }}
                                >
                                </img>
                                <p
                                    style={params.activeEvents.length > 2 ?
                                        pcTilteStyleMany : params.activeEvents.length === 2 ?
                                            pcTitleStyleTwo : pcTitleStyleOne}
                                >{stared.title}</p>
                                <p
                                    style={params.activeEvents.length > 2 ?
                                        pcMonthStyleMany : params.activeEvents.length === 2 ?
                                            pcMonthStyleTwo : pcMonthStyleOne}
                                >{stared.month}</p>
                            </div>
                            {
                                params.activeEvents.reduce((acc, ev) => {
                                    if (!ev.stared) {
                                        acc.push(
                                            <div
                                                style={params.activeEvents.length > 2 ?
                                                    pcStyleMany : params.activeEvents.length === 2 ?
                                                        pcStyleTwo : pcStyleOne}
                                            >
                                                <img
                                                    src={`https://pics.boreas.paris/${ev.picture}`}
                                                    onClick={() => params.setSelected(ev)}
                                                    style={{
                                                        borderRadius: "20px",
                                                        height: "85%",
                                                        width: "100%",
                                                        border: "rgba(242, 135, 5, 0.479) 2px solid",
                                                        cursor: "pointer"
                                                    }}
                                                >
                                                </img>
                                                <p
                                                    style={params.activeEvents.length > 2 ?
                                                        pcTilteStyleMany : params.activeEvents.length === 2 ?
                                                            pcTitleStyleTwo : pcTitleStyleOne}
                                                >{ev.title}</p>
                                                <p
                                                    style={params.activeEvents.length > 2 ?
                                                        pcMonthStyleMany : params.activeEvents.length === 2 ?
                                                            pcMonthStyleTwo : pcMonthStyleOne}
                                                >{ev.month}</p>
                                            </div>
                                        )
                                    }
                                    return (
                                        acc
                                    )
                                }, [])
                            }
                        </div>
                        <div id="displayed-event">
                            <DisplayEvent truncatedFlyer={truncatedFlyer}
                                handleFrameClick={handleFrameClick}
                                eventToDisplay={params.selected}
                                language={params.language}
                                setChosenDate={params.setChosenDate}
                                chosenDate={params.chosenDate}
                                servPort={params.servPort}
                            />
                        </div>
                    </div>
                }
                {
                    isMobile &&
                    <Header
                        isMobile={isMobile}
                        displayHeader={displayHeader}
                        setDisplayMobileMenu={setDisplayMobileMenu}
                        displayMobileMenu={displayMobileMenu}
                        burgerOrClose={burgerOrClose}
                    />
                }
                {
                    isMobile &&
                    <div id="events-wrapper-mobile">
                        <div id="events-list-mobile">
                            <HashLink
                                smooth
                                to="#displayed-event-mobile"
                                style={params.activeEvents.length > 1 ?
                                    mobileStyleMany : mobileStyleOne}
                            >
                                <img
                                    src={`https://pics.boreas.paris/${stared.picture}`}
                                    style={{
                                        height: "85%",
                                        width: "100%",
                                        borderRadius: "20px",
                                    }}
                                    onClick={() => params.setSelected(stared)}
                                >
                                </img>
                                <p
                                    style={params.activeEvents.length > 1 ?
                                        mobileTitleMany : mobileTitleOne}
                                >{stared.title}</p>
                                <p
                                    style={params.activeEvents.length > 1 ?
                                        mobileMonthMany : mobileMonthOne}
                                >{stared.month}</p>
                            </HashLink>
                            {
                                params.activeEvents.reduce((acc, ev) => {
                                    if (!ev.stared) {
                                        acc.push(
                                            <HashLink
                                                smooth
                                                to="#displayed-event-mobile"
                                                style={params.activeEvents.length > 1 ?
                                                    mobileStyleMany : mobileStyleOne}
                                            >
                                                <img
                                                    src={`https://pics.boreas.paris/${ev.picture}`}
                                                    style={{
                                                        height: "85%",
                                                        width: "100%",
                                                        borderRadius: "20px",
                                                    }}
                                                    onClick={() => params.setSelected(ev)}
                                                >
                                                </img>
                                                <p
                                                    style={params.activeEvents.length > 1 ?
                                                        mobileTitleMany : mobileTitleOne}
                                                >{ev.title}</p>
                                                <p
                                                    style={params.activeEvents.length > 1 ?
                                                        mobileMonthMany : mobileMonthOne}
                                                >{ev.month}</p>
                                            </HashLink>
                                        )
                                    }
                                    return (
                                        acc
                                    )
                                }, [])
                            }
                        </div>
                        <div id="displayed-event-mobile">
                            <DisplayEvent truncatedFlyer={truncatedFlyer}
                                handleFrameClick={handleFrameClick}
                                eventToDisplay={params.selected}
                                language={params.language}
                                setChosenDate={params.setChosenDate}
                                chosenDate={params.chosenDate}
                                servPort={params.servPort}
                            />
                        </div>
                    </div>
                }
                <Footer />
            </div>
        )
    }

}

export default Events