import { useState } from "react"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


function EventCreator(params) {

    const [title, setTilte] = useState(params.title)
    const [subtitle, setSubtitle] = useState(params.subtitle)
    const [mainText, setMainText] = useState(params.mainText)
    const [informations, setInformations] = useState(params.informations)
    const [stopover, setStopover] = useState(params.stopover)
    const [stopoverName, setStopoverName] = useState(params.stopoverName)
    const [picture, setPicture] = useState(params.picture)
    const [dates, setDates] = useState(params.dates)
    const [isActive, setIsActive] = useState(params.isActive)
    const [stared, setStared] = useState(params.stared)
    const [month, setMonth] = useState(params.month)
    const [stopoverLink, setStopoverLink] = useState(params.stopoverLink)
    const [price, setPrice] = useState(params.price)
    const [kidPrice, setKidPrice] = useState(params.kidPrice)
    const [vipAvailable, setVipAvailable] = useState(params.vipAvailable)
    const [vipText, setVipText] = useState(params.vipText)
    const [vipPrice, setVipPrice] = useState(params.vipPrice)
    const [vipKidPrice, setVipKidPrice] = useState(params.vipKidPrice)
    const [leavingAt, setLeavingAt] = useState(params.leavingAt)
    const [returningAt, setReturningAt] = useState(params.returningAt)
    const [navigationDuration, setNavigationDuration] = useState(params.navigationDuration)
    const [menu1Title, setMenu1Title] = useState(params.menu1Title)
    const [menu2Title, setMenu2Title] = useState(params.menu2Title)
    const [menu1Link, setMenu1Link] = useState(params.menu1Link)
    const [menu2Link, setMenu2Link] = useState(params.menu2Link)
    const [kidMenuLink, setKidMenuLink] = useState(params.kidMenuLink)
    const [option1, setOption1] = useState(params.option1)
    const [option1Price, setOption1Price] = useState(params.option1Price)
    const [option1Description, setOption1Description] = useState(params.option1Description)
    const [option1Detail, setOption1Details] = useState(params.option1Detail)
    const [option2, setOption2] = useState(params.option2)
    const [option2Price, setOption2Price] = useState(params.option2Price)
    const [option2Description, setOption2Description] = useState(params.option2Description)
    const [option2Detail, setOption2Details] = useState(params.option2Detail)
    const [maximumCapacity, setMaximumCapacity] = useState(params.maximumCapacity)
    const [showPlacesLeftFrom, setShowPlacesLeftFrom] = useState(params.showPlacesLeftFrom)
    const [pairBooking, setPairBooking] = useState(params.pairBooking)
    const [date, setDate] = useState(new Date())
    const [serverAnswer, setServerAnswer] = useState("")

    console.log("*************************************")
    console.log("date")
    console.log(date)
    console.log("dates:")
    console.log(dates)
    console.log("*************************************")

    function deleteDate(date) {
        let tmp = dates.filter(elem => elem !== date)
        setDates(tmp)
    }

    function addEvent() {
        fetch(`https://boreasapi.cyrilmorin.fr:${params.servPort}/addevent`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                title: title,
                subtitle: subtitle,
                mainText: mainText,
                informations: informations,
                stopover: stopover,
                stopoverName: stopoverName,
                stopoverLink: stopoverLink,
                picture: picture,
                dates: dates,
                isActive: isActive,
                stared: stared,
                password: params.password,
                month: month,
                price: price,
                kidPrice: kidPrice,
                vipAvailable: vipAvailable,
                vipText: vipText,
                vipPrice: vipPrice,
                vipKidPrice: vipKidPrice,
                leavingAt: leavingAt,
                returningAt: returningAt,
                navigationDuration: navigationDuration,
                menu1Title: menu1Title,
                menu1Link: menu1Link,
                menu2Title: menu2Title,
                menu2Link: menu2Link,
                kidMenuLink: kidMenuLink,
                option1: option1,
                option1Description: option1Description,
                option1Price: option1Price,
                option1Detail: option1Detail,
                option2: option2,
                option2Description: option2Description,
                option2Price: option2Price,
                option2Detail: option2Detail,
                pairBooking: pairBooking,
                maximumCapacity: maximumCapacity,
                showPlacesLeftFrom: showPlacesLeftFrom
            })
        }).then(res => {
            return res.json();
        })
            .then(data => {
                setServerAnswer(data.response)
            });
    }

    function handleOkPopup() {
        setServerAnswer("")
        params.getEventList()
    }

    return (
        <div>
            {
                serverAnswer &&
                <div
                    style={{
                        position: "fixed",
                        left: 0,
                        top: 0,
                        height: "100vh",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "darkgreen",
                        zIndex: 10
                    }}
                >
                    <p>{serverAnswer}</p>
                    <button
                        onClick={() => handleOkPopup()}
                    >OK !</button>
                </div>
            }
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingBottom: "15vh",
                    paddingTop: "5vh",
                    border: "solid 5px red"
                }}
            >
                <p>titre :</p>
                {
                    params.allowTitleChange &&
                    <input
                        value={title}
                        onChange={(e) => setTilte(e.target.value)}
                    />}
                {
                    !params.allowTitleChange &&
                    <p
                        style={{
                            fontSize: "calc(1.2vh + 1.2vw)"
                        }}
                    >{title}</p>
                }
                <p>sous titre :</p>
                <input
                    value={subtitle}
                    onChange={(e) => setSubtitle(e.target.value)}
                />
                <p>texte principal :</p>
                <textarea
                    style={{
                        height: "100px",
                        width: "600px"
                    }}
                    value={mainText}
                    onChange={(e) => setMainText(e.target.value)}
                />
                <p>informations :</p>
                <textarea
                    style={{
                        height: "100px",
                        width: "600px"
                    }}
                    value={informations}
                    onChange={(e) => setInformations(e.target.value)}
                />
                <p>éscale (infos générales):</p>
                <textarea
                    style={{
                        height: "100px",
                        width: "600px"
                    }}
                    value={stopover}
                    onChange={(e) => setStopover(e.target.value)}
                />
                <p>Lien de l'éscale :</p>
                <input
                    value={stopoverLink}
                    onChange={(e) => setStopoverLink(e.target.value)}
                />
                <p>Nom de l'éscale (Grennele):</p>
                <input
                    value={stopoverName}
                    onChange={(e) => setStopoverName(e.target.value)}
                />
                <p>Départ (example : 19h30 - Départ quai Grenelle) :</p>
                <input
                    value={leavingAt}
                    onChange={(e) => setLeavingAt(e.target.value)}
                />
                <p>Retour (example : 22h00 - Retour quai Grenelle):</p>
                <input
                    value={returningAt}
                    onChange={(e) => setReturningAt(e.target.value)}
                />
                <p>Durée de navigation (example: 2h30) :</p>
                <input
                    value={navigationDuration}
                    onChange={(e) => setNavigationDuration(e.target.value)}
                />
                <p>nom de la photo (UPLOAD à la racine de PICS,
                    s'assurer que la photo a été uplodé au préalable sur le serveur, et que le nom est exactement le même) :</p>
                <input
                    value={picture}
                    onChange={(e) => setPicture(e.target.value)}
                />
                <p>dates :</p>
                <DatePicker
                    className="form-input simple-input"
                    onChange={(e) => setDate(e)}
                    selected={date}
                    dateFormat="dd/MM/yyyy"
                ></DatePicker>
                <button
                    onClick={() => setDates(() => {
                        let tmp = [...dates]
                        if (!tmp.includes(date)) {
                            tmp.push(date)
                        }
                        tmp.sort(function (a, b) {
                            return a - b
                        })
                        return tmp
                    })}
                >Ajouter cette date</button>
                {
                    dates.map(date => {
                        if (Object.prototype.toString.call(date) === '[object Date]') {
                            console.log("date format ok")
                            console.log(date)
                            return (
                                <div
                                    key={date.toLocaleDateString(params.language)}
                                    style={{
                                        display: "flex",
                                        height: "30px",
                                        width: "250px",
                                        alignItems: "center",
                                        justifyContent: "space-around",
                                        backgroundColor: "darkgreen",
                                        marginTop: "1vh"
                                    }}
                                >
                                    <p>{date.toLocaleDateString(params.language)}</p>
                                    <button
                                        onClick={() => deleteDate(date)}
                                    >supprimer cette date</button>
                                </div>
                            )
                        } else {
                            console.log("bad date format")
                            console.log(date)
                            console.log("type of date :")
                            console.log(typeof (date))
                        }
                    })
                }
                <p>Mois :</p>
                <select
                    onChange={(e) => setMonth(e.target.value)}
                    value={month}
                >
                    <option key={"RECURRENT"} value={"RECURRENT"}>{"RECURRENT"}</option>
                    <option key={"JANVIER"} value={"JANVIER"}>{"JANVIER"}</option>
                    <option key={"FEVRIER"} value={"FEVRIER"}>{"FEVRIER"}</option>
                    <option key={"MARS"} value={"MARS"}>{"MARS"}</option>
                    <option key={"AVRIL"} value={"AVRIL"}>{"AVRIL"}</option>
                    <option key={"MAI"} value={"MAI"}>{"MAI"}</option>
                    <option key={"JUIN"} value={"JUIN"}>{"JUIN"}</option>
                    <option key={"JUILLET"} value={"JUILLET"}>{"JUILLET"}</option>
                    <option key={"AOUT"} value={"AOUT"}>{"AOUT"}</option>
                    <option key={"SEPTEMBRE"} value={"SEPTEMBRE"}>{"SEPTEMBRE"}</option>
                    <option key={"OCTOBRE"} value={"OCTOBRE"}>{"OCTOBRE"}</option>
                    <option key={"NOVEMBRE"} value={"NOVEMBRE"}>{"NOVEMBRE"}</option>
                    <option key={"DECEMBRE"} value={"DECEMBRE"}>{"DECEMBRE"}</option>
                </select>
                <p>Prix par adulte :</p>
                <input
                    type="number"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                />
                <p>Prix par enfant (doit être renseigné même si il est égale au prix adulte) :</p>
                <input
                    type="number"
                    value={kidPrice}
                    onChange={(e) => setKidPrice(e.target.value)}
                />
                <p>Option Vip:</p>
                <input
                    type="checkbox"
                    onChange={() => setVipAvailable(!vipAvailable)}
                    defaultChecked={vipAvailable}
                />
                {
                    vipAvailable &&
                    <div>
                        <p>Text descriptif de l'option</p>
                        <textarea
                            style={{
                                height: "100px",
                                width: "600px"
                            }}
                            value={vipText}
                            onChange={(e) => setVipText(e.target.value)}
                        />
                        <p>Prix VIP par personne</p>
                        <input
                            type="number"
                            value={vipPrice}
                            onChange={(e) => setVipPrice(e.target.value)}
                        />
                        <p>Prix VIP par enfant (doit être renseigné même si il est égale au prix adulte)</p>
                        <input
                            type="number"
                            value={vipKidPrice}
                            onChange={(e) => setVipKidPrice(e.target.value)}
                        />
                    </div>
                }
                {
                    !vipAvailable &&
                    <div>
                        <p>titre menu 1</p>
                        <input
                            value={menu1Title}
                            onChange={(e) => setMenu1Title(e.target.value)}
                        />
                        <p>lien menu 1</p>
                        <input
                            value={menu1Link}
                            onChange={(e) => setMenu1Link(e.target.value)}
                        />
                        <p>titre menu 2</p>
                        <input
                            value={menu2Title}
                            onChange={(e) => setMenu2Title(e.target.value)}
                        />
                        <p>lien menu 2</p>
                        <input
                            value={menu2Link}
                            onChange={(e) => setMenu2Link(e.target.value)}
                        />
                        <p>lien menu enfant</p>
                        <input
                            value={kidMenuLink}
                            onChange={(e) => setKidMenuLink(e.target.value)}
                        />
                        <p>titre option 1</p>
                        <input
                            value={option1}
                            onChange={(e) => setOption1(e.target.value)}
                        />
                        <p>description option 1</p>
                        <input
                            value={option1Description}
                            onChange={(e) => setOption1Description(e.target.value)}
                        />
                        <p>Prix option 1</p>
                        <input
                            type="number"
                            value={option1Price}
                            onChange={(e) => setOption1Price(e.target.value)}
                        />
                        {/* <p>PRIX par PERSONNE ou pour le GROUPE ?</p>
                        <div>
                            <button
                                style={{
                                    backgroundColor: option1Detail.pricePer === "person" ? "lightgreen" : "grey"
                                }}
                                onClick={(e) => setOption1Details(option1Detail => ({
                                    ...option1Detail,
                                    pricePer: "person"
                                }))}
                            >personne</button>
                            <button
                                style={{
                                    backgroundColor: option1Detail.pricePer === "group" ? "lightgreen" : "grey"
                                }}
                                onClick={(e) => setOption1Details(option1Detail => ({
                                    ...option1Detail,
                                    pricePer: "group"
                                }))}
                            >groupe</button>
                        </div> */}
                        <p>Quantité maximum TOTALE (0 = aucune limite) </p>
                        <input
                            type="number"
                            value={option1Detail.limitAvailable}
                            onChange={(e) => setOption1Details(option1Detail => ({
                                ...option1Detail,
                                limitAvailable: e.target.value
                            }))}
                        />
                        {/* <p>Limite par PERSONNE ou par GROUPE ?</p>
                        <div>
                            <button
                                style={{
                                    backgroundColor: option1Detail.limitAvailableType === "person" ? "lightgreen" : "grey"
                                }}
                                onClick={(e) => setOption1Details(option1Detail => ({
                                    ...option1Detail,
                                    limitAvailableType: "person"
                                }))}
                            >personne</button>
                            <button
                                style={{
                                    backgroundColor: option1Detail.limitAvailableType === "group" ? "lightgreen" : "grey"
                                }}
                                onClick={(e) => setOption1Details(option1Detail => ({
                                    ...option1Detail,
                                    limitAvailableType: "group"
                                }))}
                            >groupe</button>
                        </div> */}
                        <p>Quantité maximum PAR PERSONNE OU GROUPE (0 = aucune limite) (limite au nombre de personnes ou de groupes)</p>
                        <input
                            type="number"
                            value={option1Detail.limitPerPersonOrGroup}
                            onChange={(e) => setOption1Details(option1Detail => ({
                                ...option1Detail,
                                limitPerPersonOrGroup: e.target.value
                            }))}
                        />
                        <p>Limite par personne ou par groupe ?</p>
                        <div>
                            <button
                                style={{
                                    backgroundColor: option1Detail.limitPerPersonOrGroupType === "person" ? "lightgreen" : "grey"
                                }}
                                onClick={(e) => setOption1Details(option1Detail => ({
                                    ...option1Detail,
                                    limitPerPersonOrGroupType: "person"
                                }))}
                            >personne</button>
                            <button
                                style={{
                                    backgroundColor: option1Detail.limitPerPersonOrGroupType === "group" ? "lightgreen" : "grey"
                                }}
                                onClick={(e) => setOption1Details(option1Detail => ({
                                    ...option1Detail,
                                    limitPerPersonOrGroupType: "group"
                                }))}
                            >groupe</button>
                        </div>
                        <p>Prix multiplié par le nombre de personne ?</p>
                        <div>
                            <button
                                style={{
                                    backgroundColor: option1Detail.multiplyByPeople === true ? "lightgreen" : "grey"
                                }}
                                onClick={(e) => setOption1Details(option1Detail => ({
                                    ...option1Detail,
                                    multiplyByPeople: true
                                }))}
                            >Oui</button>
                            <button
                                style={{
                                    backgroundColor: option1Detail.multiplyByPeople === false ? "lightgreen" : "grey"
                                }}
                                onClick={(e) => setOption1Details(option1Detail => ({
                                    ...option1Detail,
                                    multiplyByPeople: false
                                }))}
                            >Non</button>
                        </div>
                        <p>titre option 2</p>
                        <input
                            value={option2}
                            onChange={(e) => setOption2(e.target.value)}
                        />
                        <p>description option 2</p>
                        <input
                            value={option2Description}
                            onChange={(e) => setOption2Description(e.target.value)}
                        />
                        <p>Prix option 2</p>
                        <input
                            type="number"
                            value={option2Price}
                            onChange={(e) => setOption2Price(e.target.value)}
                        />
                        {/* <p>PRIX par PERSONNE ou pour le GROUPE ?</p>
                        <div>
                            <button
                                style={{
                                    backgroundColor: option2Detail.pricePer === "person" ? "lightgreen" : "grey"
                                }}
                                onClick={(e) => setOption2Details(option2Detail => ({
                                    ...option2Detail,
                                    pricePer: "person"
                                }))}
                            >personne</button>
                            <button
                                style={{
                                    backgroundColor: option2Detail.pricePer === "group" ? "lightgreen" : "grey"
                                }}
                                onClick={(e) => setOption2Details(option2Detail => ({
                                    ...option2Detail,
                                    pricePer: "group"
                                }))}
                            >groupe</button>
                        </div> */}
                        <p>Quantité maximum TOTALE (0 = aucune limite))</p>
                        <input
                            type="number"
                            value={option2Detail.limitAvailable}
                            onChange={(e) => setOption2Details(option2Detail => ({
                                ...option2Detail,
                                limitAvailable: e.target.value
                            }))}
                        />
                        {/* <p>Limite par PERSONNE ou par GROUPE ?</p>
                        <div>
                            <button
                                style={{
                                    backgroundColor: option2Detail.limitAvailableType === "person" ? "lightgreen" : "grey"
                                }}
                                onClick={(e) => setOption2Details(option2Detail => ({
                                    ...option2Detail,
                                    limitAvailableType: "person"
                                }))}
                            >personne</button>
                            <button
                                style={{
                                    backgroundColor: option2Detail.limitAvailableType === "group" ? "lightgreen" : "grey"
                                }}
                                onClick={(e) => setOption2Details(option2Detail => ({
                                    ...option2Detail,
                                    limitAvailableType: "group"
                                }))}
                            >groupe</button>
                        </div> */}
                        <p>Quantité maximum PAR PERSONNE OU GROUPE (0 = aucune limite) (limite au nombre de personnes ou de groupes)</p>
                        <input
                            type="number"
                            value={option2Detail.limitPerPersonOrGroup}
                            onChange={(e) => setOption2Details(option2Detail => ({
                                ...option2Detail,
                                limitPerPersonOrGroup: e.target.value
                            }))}
                        />
                        <p>Limite par personne ou par groupe ?</p>
                        <div>
                            <button
                                style={{
                                    backgroundColor: option2Detail.limitPerPersonOrGroupType === "person" ? "lightgreen" : "grey"
                                }}
                                onClick={(e) => setOption2Details(option2Detail => ({
                                    ...option2Detail,
                                    limitPerPersonOrGroupType: "person"
                                }))}
                            >personne</button>
                            <button
                                style={{
                                    backgroundColor: option2Detail.limitPerPersonOrGroupType === "group" ? "lightgreen" : "grey"
                                }}
                                onClick={(e) => setOption2Details(option2Detail => ({
                                    ...option2Detail,
                                    limitPerPersonOrGroupType: "group"
                                }))}
                            >groupe</button>
                        </div>
                        <p>Prix multiplié par le nombre de personne ?</p>
                        <div>
                            <button
                                style={{
                                    backgroundColor: option2Detail.multiplyByPeople === true ? "lightgreen" : "grey"
                                }}
                                onClick={(e) => setOption2Details(option2Detail => ({
                                    ...option2Detail,
                                    multiplyByPeople: true
                                }))}
                            >Oui</button>
                            <button
                                style={{
                                    backgroundColor: option2Detail.multiplyByPeople === false ? "lightgreen" : "grey"
                                }}
                                onClick={(e) => setOption2Details(option2Detail => ({
                                    ...option2Detail,
                                    multiplyByPeople: false
                                }))}
                            >Non</button>
                        </div>
                    </div>
                }
                <p>Maximum de personnes pour l'event:</p>
                <input
                type="number"
                value={maximumCapacity}
                onChange={(e) => setMaximumCapacity(e.target.value)}
                />
                <p>Montrer les places restantes disponible à partir de combien de place restantes</p>
                <input
                type="number"
                value={showPlacesLeftFrom}
                onChange={(e) => setShowPlacesLeftFrom(e.target.value)}
                />
                <p>Réservation par paire ?</p>
                <input
                        type="checkbox"
                        onChange={() => setPairBooking(!pairBooking)}
                        defaultChecked={pairBooking}
                    />
                <div
                    style={{
                        display: "flex",
                        height: "30px",
                        width: "650px",
                        alignItems: "center",
                        justifyContent: "space-around",
                        marginTop: "2vh"
                    }}
                >
                    <p>L'événement est actif ? (apparait dans la liste) :</p>
                    <input
                        type="checkbox"
                        onChange={() => setIsActive(!isActive)}
                        defaultChecked={isActive}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        height: "30px",
                        width: "650px",
                        alignItems: "center",
                        justifyContent: "space-around",
                        marginTop: "2vh"
                    }}
                >
                    <p>L'événement est à la une ? (vérifier qu'il n'y en a qu'un) :</p>
                    <input
                        type="checkbox"
                        onChange={() => setStared(!stared)}
                        defaultChecked={stared}
                    />
                </div>
                {
                    dates.length > 0 &&
                    <button
                        style={{
                            fontSize: "calc(1.2vh + 1.2vw)",
                            marginTop: "3vh"
                        }}
                        onClick={() => addEvent()}
                    >Ajouter / M à J</button>
                }
                {
                    dates.length === 0 &&
                    <button
                        style={{
                            fontSize: "calc(1.2vh + 1.2vw)",
                            marginTop: "3vh"
                        }}
                    >Aucune date n'est renseigné</button>
                }

            </div>
        </div>
    )
}

export default EventCreator

/*
pop up de retour message serv, quand on clique sur ok
on fetch à nouveau la list depuis la DB
*/