import { useHistory } from "react-router-dom"

function FlyerDisplayer(params) {

    let history = useHistory()

    return (
        <div
            onClick={() => window.history.back()}
            style={{
                height: "100%",
                width: "100%",
                position: 'fixed',
                left: 0,
                top: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: "center",
                zIndex: 100
            }}
        >
            <img
                id="flyer-big"
                style={{
                    border: "solid 1px rgb(242, 135, 5)",
                    borderRadius: "20px",
                    zIndex: 100
                }}

                src={params.src} />
        </div>
    )
}

export default FlyerDisplayer