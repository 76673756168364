import { Link } from 'react-router-dom'

function Footer(params) {

    return (
        <div id="footer">
            <Link to="/contact"
                onClick={() => {
                    setTimeout(() => {
                        window.scrollTo({ top: 0, behavior: "smooth" })
                    }, 50)
                }
                }
            >
                <p className="footer-text">CONTACT</p>
                {/* p tag displaying 06 50 22 11 20 as a phone and is clickable to call directly */}
            </Link>
            <p className="footer-text" href="tel:+33 6 50 22 11 20">06 50 22 11 20</p>
            <p className="footer-text" mailto="roman@boreas.paris">roman@boreas.paris</p>
        </div>
    )
}

export default Footer