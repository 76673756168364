import { useEffect, useState } from "react"
import downArrow from "../icons/down-arrow.svg"


function MyBookingDetails(params) {

    const [mybookingData, setMybookingData] = useState(null)
    const [displayInfo, setDisplayInfo] = useState(false)

    useEffect(() => {
        if (params.requestMode) {
            fetch(`https://boreasapi.cyrilmorin.fr:${params.servPort}/getmybooking`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: params.email,
                    bookingCode: params.bookingCode
                })
            })
                .then(res => {
                    return res.json();
                })
                .then(data => {
                    if (data) {
                        setMybookingData(data)
                    }
                })
        } else {
            setMybookingData(params)
        }
    }, [])


    return (
        <div id="mybooking-wrapper">
            {
                !mybookingData &&
                <p>Loading...</p>
            }
            {
                mybookingData &&
                <div>
                    {
                        !displayInfo &&
                        <div id="mybooking-title-and-price">
                            <p
                                style={{
                                    textTransform: "uppercase"
                                }}
                            >{mybookingData.eventName}</p>
                            <p
                                id="mybookingdetails-price"
                                style={{
                                    color: "rgb(242, 135, 5)"
                                }}
                            >{mybookingData.price} €</p>
                        </div>
                    }
                    {
                        displayInfo &&
                        <div id="mbd-detail-sec">
                            <p id="mbd-top-title">événnement :</p>
                            <div
                                onClick={() => params.handleFrameClick(`https://pics.boreas.paris/${params.picture}`)}
                                id="mbd-pic"
                                style={{
                                    backgroundImage: `url("https://pics.boreas.paris/${params.picture}")`,
                                    cursor: "pointer",
                                }}
                            >
                            </div>
                            <p id="mbd-event-title">{mybookingData.eventName}</p>
                            <p id="mbd-event-date"
                                className="mybooking-info-text">Le {mybookingData.day} {mybookingData.date}</p>
                            <p className="mybooking-info-text">{mybookingData.leavingAt} - {mybookingData.returningAt}</p>
                            <div className="mbd-split" />
                            <p id="mbd-event-stopover">éscale <a style={{ textDecoration: "underline" }} target="_blank" href={mybookingData.stopoverLink}>{mybookingData.stopoverName}</a></p>
                            <p className="mybooking-info-text">{mybookingData.stopover}</p>
                            <div className="mbd-split" />
                            <p id="mbd-ticket-type">ticket {mybookingData.vipOption ? "vip" : ""} boreas</p>
                            <div className="mbd-people-details">
                                <p className="mybooking-info-text">{mybookingData.numberOfGuests} Adulte{mybookingData.numberOfGuests > 1 ? "s" : ""}</p>
                                <p className="mybooking-info-text">{mybookingData.numberOfGuests * mybookingData.adultPrice} €</p>
                            </div>
                            {
                                mybookingData.numberOfKid !== 0 &&
                                <div className="mbd-people-details">
                                    <p className="mybooking-info-text">{mybookingData.numberOfKid} Enfant{mybookingData.numberOfKid > 1 ? "s" : ""}</p>
                                    <p className="mybooking-info-text">{mybookingData.numberOfKid * mybookingData.kidPrice} €</p>
                                </div>}
                            {
                                mybookingData.optionPrice !== 0 &&
                                <div className="mbd-people-details">
                                    <p className="mybooking-info-text">Options</p>
                                    <p className="mybooking-info-text">{mybookingData.optionPrice} €</p>
                                </div>}
                            {
                                mybookingData.promoAmount !== 0 &&
                                <div className="mbd-people-details">
                                    <p className="mybooking-info-text">Code promo</p>
                                    <p className="mybooking-info-text">-{mybookingData.promoAmount} €</p>
                                </div>}
                            <div className="mbd-split" />
                            <div className="mbd-people-details">
                                <p className="mbd-total-price">total de la prestation</p>
                                <p className="mbd-total-price">{mybookingData.price} €</p>
                            </div>
                            <div className="mbd-split" />
                        </div>
                    }
                    <div
                        onClick={() => setDisplayInfo(!displayInfo)}
                        id="mybooking-display">
                        <p
                            style={{
                                textDecoration: "underline",
                                textAlign: "center"
                            }}
                            className="mybooking-info-text">{displayInfo ? "Masquer votre billet" : "Afficher les détails de votre ticket"}</p>
                        <img
                            src={downArrow} className="down-arrow"
                            style={{
                                transform: displayInfo ? "rotate(180deg)" : "rotate(0deg)"
                            }}
                        />
                    </div>
                </div>

            }
        </div >
    )
}


// {
//     displayInfo &&
//     <div
//         style={{
//             width: "90%",
//             marginLeft: "5%",
//             height: "2px",
//             backgroundColor: "rgb(242, 135, 5)",
//             marginBottom: "40px"
//         }}
//     />
// }

// {
//     displayInfo &&
//     <div id="mybooking-info">
//         <div
//             id="picture-frame"
//             style={{
//                 backgroundImage: `url("https://pics.boreas.paris/${params.picture}")`,
//                 cursor: "default",
//                 marginBottom: "5%"
//             }}
//         >
//         </div>
//         <p
//             style={{
//                 textTransform: "capitalize"
//             }}
//             className="mybooking-info-text">{mybookingData.day} {mybookingData.date}</p>
//         <p className="mybooking-info-text">{mybookingData.leavingAt} 
//         {/* <a
//             style={{
//                 color: "rgb(242, 135, 5)",
//                 textDecoration: "underline"
//             }}
//             href={mybookingData.stopoverLink}
//             target="_blank"
//         >(plan)</a> */}
//         </p>
//         <p className="mybooking-info-text">{mybookingData.navigationDuration} de navigation</p>
//         <p className="mybooking-info-text">{mybookingData.returningAt} 
//         {/* <a
//             style={{
//                 color: "rgb(242, 135, 5)",
//                 textDecoration: "underline"
//             }}
//             href={mybookingData.stopoverLink}
//             target="_blank"
//         >(plan)</a> */}
//         </p>
//         <p
//             style={{
//                 color: "red"
//             }}
//             className="mybooking-info-text">Embarquement 30 minutes
//             avant le départ</p>
//         <p
//             style={{
//                 color: "red"
//             }}
//             className="mybooking-info-text">Présentation du &nbsp;
//             <span
//                 style={{
//                     textDecoration: "underline"
//                 }}
//             >pass
//                 sanitaire</span>
//             &nbsp;obligatoire selon les mesures en vigueur</p>

//     </div>
// }
// {
//     displayInfo &&
//     <div
//         style={{
//             width: "90%",
//             marginLeft: "5%",
//             height: "2px",
//             backgroundColor: "rgb(242, 135, 5)",
//             marginBottom: "40px",
//             marginTop: "40px"
//         }}
//     />
// }

// {
//     displayInfo &&
//     <div
//         style={{
//             width: "90%",
//             marginLeft: "5%",
//             height: "2px",
//             backgroundColor: "rgb(242, 135, 5)",
//             marginBottom: "40px",
//             marginTop: "40px"
//         }}
//     />
// }

export default MyBookingDetails