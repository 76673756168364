
function CookiesPage(params) {
    return (
        <div
            className="cookies-wrapper"
        >
            <h1
            style={{
                textAlign: "center",
                marginBottom: "2vh"
            }}
            >Politique de confidentialité</h1>
            <div
            className="cookies-text-wrapper"
            >
                <p >Pour accéder au site et respecter les règles de confidentialité, vous devez lire et approuver la politique de confidentialité ! (cliquer sur ok dans le pop-up de bas de page)</p>
                <p >1.Collecte de l’information</p>
                <p >Nous recueillons des informations lorsque vous vous inscrivez sur notre site, lorsque vous vous connectez à votre compte, faites un achat, participez à un concours, et / ou lorsque vous vous déconnectez. Les informations recueillies incluent votre nom, votre adresse e-mail, numéro de téléphone.</p>
                <p >En outre, nous recevons et enregistrons automatiquement des informations à partir de votre ordinateur et navigateur, y compris votre adresse IP, vos logiciels et votre matériel, et la page que vous demandez.</p>
                <p >2.Utilisation des informations</p>
                <p >Toute les informations que nous recueillons auprès de vous peuvent être utilisées pour :</p>
                <ul >
                    <li>Personnaliser votre expérience et répondre à vos besoins individuels</li>
                    <li>Améliorer notre site Web</li>
                    <li>Améliorer le service client et vos besoins de prise en charge</li>
                    <li>Vous contacter par e-mail dans le cadre uniquement de votre demande</li>
                </ul>
                <p >3.Confidentialité du commerce en ligne</p>
                <p >Nous sommes les seuls propriétaires des informations recueillies sur ce site. Vos informations personnelles ne seront pas vendues, échangées, transférées, ou données à une autre société pour n’importe quel raison, sans votre consentement, en dehors de ce qui est nécessaire pour répondre à une demande et / ou une transaction, comme par exemple pour expédier une commande.</p>
                <p >4.Divulgation à des tiers</p>
                <p >Nous ne vendons, n’échangeons et ne transférons pas vos informations personnelles identifiables à des tiers. Cela ne comprend pas les tierce parties de confiance qui nous aident à exploiter notre site Web ou à mener nos affaires, tant que ces parties conviennent de garder ces informations confidentielles.</p>
                <p >Nous pensons qu’il est nécessaire de partager des informations afin d’enquêter, de prévenir ou de prendre des mesures concernant des activités illégales, fraudes présumées, situations impliquant des menaces potentielles à la sécurité physique de toute personne, violations de nos conditions d’utilisation, ou quand la loi nous y contraint.</p>
                <p >5.Protection des informations</p>
                <p >Nous mettons en œuvre une variété de mesures de sécurité pour préserver la sécurité de vos informations personnelles. Nous utilisons un cryptage à la pointe de la technologie (https) pour protéger les informations sensibles transmises en ligne. Seuls les employés qui ont besoin d’effectuer un travail spécifique (par exemple, la facturation ou le service à la clientèle) ont accès aux informations personnelles identifiables. Les ordinateurs et serveurs utilisés pour stocker des informations personnelles identifiables sont conservés dans un environnement sécurisé.</p>
                <p >Est-ce que nous utilisons des cookies ?</p>
                <p >Oui. Nos cookies améliorent l’accès à notre site et identifient les visiteurs réguliers. En outre, nos cookies améliorent l’expérience d’utilisateur grâce au suivi et au ciblage de ses intérêts. Cependant, cette utilisation des cookies n’est en aucune façon liée à des informations personnelles identifiables sur notre site.</p>
                <p >6.Se désabonner</p>
                <p >Nous utilisons l’adresse e-mail que vous fournissez pour vous envoyer des informations et mises à jour relatives à votre commande, des nouvelles de l’entreprise de façon occasionnelle, des informations sur des produits liés, etc. Si à n’importe quel moment vous souhaitez vous désinscrire et ne plus recevoir d’e-mails, des instructions de désabonnement détaillées sont incluses en bas de chaque e-mail.</p>
                <p >7.Consentement<br />En utilisant notre site, vous consentez à notre politique de confidentialité.</p>
                <p > Dans le cadre de la loi Européenne du 14 Avril 2016 sur la règlement Général sur la protection des données personnelles, nous vous remercions de nous contacer afin de nous indiquer votre souhait. Aussi, nous vous informons que les données recueillies par le formulaire de contact et celui de la page d’accueil sont à titre purement informelle afin de pouvoir vous fournir dans les meilleurs délais un devis gratuit relatif à votre demande.Aucune information est revendue ou utilisée à des fins de prospections.</p>
                <p
                    className=""
                >
                    Réservations et renseignements : <a
                        style={{
                            color: "black",
                            textDecoration: "underline"
                        }}
                        href="mailto:zara@free.fr">zara@free.fr</a> (06.28.05.54.15 / 06.07.74.09.32)
                </p>
            </div>
        </div>
    )
}

export default CookiesPage
