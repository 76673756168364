import { useEffect, useState } from "react"
import { useLocation, Link } from "react-router-dom"
import headerBg from "../banners/navbar.png"
import downArrow from "../icons/down-arrow.svg"
import { HashLink } from 'react-router-hash-link'
import { CSSTransition } from 'react-transition-group'

function Header(params) {

    if (params.isMobile) {
        return (
            <CSSTransition
                mountOnEnter
                unmountOnExit
                in={params.displayHeader}
                timeout={500}
                classNames="header-transition"
            >
                <div className="header-mobile"
                >
                    <img id="hamburger" src={params.burgerOrClose}
                        onClick={() => params.setDisplayMobileMenu(!params.displayMobileMenu)}
                    />
                    <img id="header-bg-mobile" src={headerBg}
                        onClick={() => params.setDisplayMobileMenu(!params.displayMobileMenu)}
                    ></img>
                    <Link to="/">
                        <div id="link-to-home-mobile"></div>
                    </Link>
                    <CSSTransition
                        mountOnEnter
                        unmountOnExit
                        in={params.displayMobileMenu}
                        timeout={200}
                        classNames="mobile-menu-transition"
                    >
                        <div id="mobile-menu">
                            <div className="navbar-wrapper-mobile">
                                <Link to="/events"><h2 className="menu-section-mobile">PROGRAMMATION</h2></Link>
                                <Link to="/privatise"><h2 className="menu-section-mobile">PRIVATISATION</h2></Link>
                                <Link to="/mybooking"><h2 className="menu-section-mobile">MA RESERVATION</h2></Link>
                                <div id="dropdown-menu-mobile"
                                >
                                    <Link
                                        to="/the-boat"><h2
                                            id="theboat"
                                            className="menu-section-mobile"
                                        >LE BATEAU-Photos</h2></Link>
                                    <Link
                                        to="/stops"
                                    ><h2
                                        className="menu-section-mobile"
                                    >LES ESCALES</h2></Link>
                                </div>
                            </div>
                        </div>
                    </CSSTransition>

                </div>
            </CSSTransition>
        )
    } else {
        return (
            <CSSTransition
                mountOnEnter
                unmountOnExit
                in={params.displayHeader}
                timeout={500}
                classNames="header-transition"
            >
                <div className="header"
                >
                    <img id="header-bg" src={headerBg}></img>
                    <Link to="/">
                        <div 
                        style={{
                            pointerEvents: "all"
                        }}
                        id="link-to-home"></div>
                    </Link>
                    <div className="navbar-wrapper">
                        <Link className="menu-section menu-element" to="/events"><h2>PROGRAMMATION</h2></Link>
                        <Link className="menu-section menu-element" to="/privatise"><h2>PRIVATISATION</h2></Link>
                        <Link className="menu-section menu-element" to="/mybooking"><h2>MA RESERVATION</h2></Link>
                        <div id="dropdown-menu"
                        >
                            <div>
                                <h2
                                    className="menu-section menu-element"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}
                                >LE BOREAS
                                    <img src={downArrow} height="10"
                                        style={{
                                            paddingLeft: "10px",
                                            marginBottom: "10px"
                                        }}
                                    />
                                </h2>

                            </div>
                            <div>
                                <Link

                                    to="/the-boat"><h2
                                        style={{
                                            borderLeft: "solid 2px rgb(242, 135, 5)",
                                            borderRight: "solid 2px rgb(242, 135, 5)"
                                        }}
                                        className="menu-element dropdown-element"
                                        id="theboat">LE BATEAU-Photos</h2></Link>
                                <div
                                    id="dropdown-midbar"
                                    style={{
                                        flexDirection: 'row',
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "3vw",
                                            height: "2px",
                                            backgroundColor: 'rgba(0, 0, 0, 0.589)',
                                            borderLeft: "solid 2px rgb(242, 135, 5)"
                                        }}
                                    ></div>
                                    <div
                                        style={{
                                            width: "6vw",
                                            height: "2px",
                                            backgroundColor: 'rgb(242, 135, 5)',
                                        }}
                                    ></div>
                                    <div
                                        style={{
                                            width: "3vw",
                                            height: "2px",
                                            backgroundColor: 'rgba(0, 0, 0, 0.589)',
                                            borderRight: "solid 2px rgb(242, 135, 5)"
                                        }}
                                    ></div>
                                </div>
                                <Link
                                    to="/stops"><h2
                                        style={{
                                            borderLeft: "solid 2px rgb(242, 135, 5)",
                                            borderRight: "solid 2px rgb(242, 135, 5)",
                                            borderBottom: "solid 2px rgb(242, 135, 5)",

                                            borderBottomLeftRadius: "20px",
                                            borderBottomRightRadius: "20px",
                                        }}
                                        className="menu-element dropdown-element">LES ESCALES</h2></Link>
                            </div>

                        </div>
                    </div>
                </div>
            </CSSTransition>
        )
    }
}

export default Header