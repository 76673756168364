import { useEffect, useState } from 'react'
import { HashLink } from 'react-router-hash-link'
import { CSSTransition } from 'react-transition-group'
import { Link, useHistory, useLocation } from 'react-router-dom'
import ReactPlayer from 'react-player/youtube'
import logo from "../logo/logo_boreas_blanc.png"
import downArrow from "../icons/down-arrow.svg"
import beerPic from "../photos/La Gaultoise Blonde.jpg"
import pinkCruiseFlyer from "../images/pinkcruiseflyer.png"
import hamburger from '../icons/menu.svg'
import close from "../icons/close.svg"
import WarningPopup from './warning-popup'
import Header from './header'
import Footer from './footer'
import DisplayEvent from './displayedevent'
import FlyerDisplayer from './flyerdisplayer'

function Home(params) {

    const isMobile = (params.ratio.width < 600 || params.ratio.width < params.ratio.height) ? true : false
    const [scrollPos, setScrollPos] = useState(0)
    const [displayHeader, setDisplayHeader] = useState(true)
    const [truncatedFlyer, setTruncatedFlyer] = useState(false)
    const [flyerSrc, setFlyerSrc] = useState(null)
    const [displayMobileMenu, setDisplayMobileMenu] = useState(false)
    const [burgerOrClose, setBurgerOrClose] = useState(hamburger)
    const [stared, setStared] = useState(null)
    const [flyerQuery, setFlyerQuery] = useState(false)
    const [retriedPI, setRetriedPI] = useState(0)

    function handleScroll() {
        if (window.scrollY > scrollPos) {
            if (window.scrollY > 50) {
                setDisplayHeader(false)
                setDisplayMobileMenu(false)
                params.setDisplayWarningPopup(false)
            }
            setScrollPos(window.scrollY)
        } else {
            setDisplayHeader(true)
            setScrollPos(window.scrollY)
        }
        let bg = document.getElementById("main-bg")
        if (window.scrollY < 1000 && window.scrollY > 200) {
            if (bg) {
                bg.style.filter = `brightness(120%) blur(${Math.floor((window.scrollY - 200) / 100)}px)`
            }
        } else if (window.scrollY <= 200) {
            if (bg) {
                bg.style.filter = `brightness(120%) blur(0px)`
            }
        }
    }

    let url1 = window.location.href
    if (url1.includes("%23")){
        url1 = window.location.href.replace(/%23/g, "#")
        window.location.href = url1
    }

    let history = useHistory()

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    let query = useQuery()

    // let paramObj = {};
    // for (var value of params.keys()) {
    //     paramObj[value] = params.get(value);
    // }

    let queryFlyer = query.get("displayflyer")
    console.log("queryflyer", queryFlyer)

    function retrievePI(tries) {
        let cachedBooking = JSON.parse(localStorage.getItem('ongoingBooking'))
        console.log("cachedBooking", cachedBooking)
        if (cachedBooking) {
            fetch(`https://boreasapi.cyrilmorin.fr:${params.servPort}/ispiok`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    pi: cachedBooking.paymentIntent
                })
            })
                .then(res => {
                    return res.json();
                })
                .then(data => {
                    console.log("response from ISPIOK :", data.response)
                    if (data.response === "pi ok") {
                        history.push({
                            pathname: "/booking",
                            search: "?step=confirmbooking"
                        })
                    } else if (tries <= 2){
                        console.log("retry_______ : ", tries)
                        setTimeout(() => {
                            retrievePI(tries + 1)
                        }, 1000);
                    }
                });
        }
    }

    useEffect(() => {
        console.log("IIIINNNN USEEFFECT !!!!!!!!!!!!!!!!!!!!!!!")
        retrievePI(0)        
    }, [])

    if (!queryFlyer) {
        if (flyerQuery === true) {
            setFlyerQuery(false)
        }
    } else if (queryFlyer === "true") {
        if (flyerQuery === false) {
            setFlyerQuery(true)
        }
    }

    function handleFrameClick(flyer) {
        setTruncatedFlyer(true)
        setFlyerSrc(flyer)
        setDisplayHeader(false)
        history.push({
            search: "?displayflyer=true"
        })
    }

    function HandleFlyerClick(params) {
        setTruncatedFlyer(false)
    }

    function fadeVideo() {
        setTimeout(() => {
            params.setVideoPlaying(false)
        }, 46500);
    }

    useEffect(() => {
        if (flyerQuery === false) {
            HandleFlyerClick()
        }
    }, [flyerQuery])

    useEffect(() => {
        params.setDisplayLoadingScreen(false)
    }, [])

    useEffect(() => {
        fetch(`https://boreasapi.cyrilmorin.fr:${params.servPort}/log`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    action: "home page loaded"
                })
            })
                .then(res => {
                    return res.json();
                })
                .then(data => {
                    console.log("response from ISPIOK :", data.res)
                });
    })

    useEffect(() => {
        window.addEventListener("scroll", handleScroll)
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [scrollPos])

    useEffect(() => {
        if (displayMobileMenu) {
            setBurgerOrClose(close)
        } else {
            setBurgerOrClose(hamburger)
        }
    }, [displayMobileMenu])

    useEffect(() => {
        if (params.activeEvents.length > 0) {
            let stared = params.activeEvents.filter(e => e.stared === true)
            if (stared) {
                setStared(stared[0])
                params.setSelected(stared[0])
            } else {
                setStared(null)
            }
        }
    }, [params.activeEvents])

    return (
        <div className="home-wrapper"
        >
            <div id="main-bg">

            </div>
            {params.cookiePopUp && <div
                id="cookie-popup-wrapper"
            >
                <div
                    id="cookie-text-wrapper"
                >
                    <p className="cookie-text">
                        Confidentialité et cookies : ce site utilise des cookies. En continuant à naviguer sur ce site, vous acceptez que nous en utilisions.
                        Pour en savoir plus, y compris sur la façon de contrôler les cookies, reportez-vous à ce qui suit :
                    </p>
                    <Link to="/cookiespage"
                        className="cookie-text"
                        style={{
                            textDecoration: "underline"
                        }}
                    >
                        Politique relative aux cookies
                    </Link>
                </div>
                <div
                    id="cookie-btn-wrapper"
                >
                    <button
                        className="product-text"
                        onClick={() => params.setCookiePopUp(false)}
                        style={{
                            cursor: 'pointer'
                        }}
                    >
                        <p>OK</p>
                    </button>
                </div>
            </div>}
            {/* {params.displayWarningPopup &&
                <div onClick={() => params.setDisplayWarningPopup(false)}>
                    <WarningPopup />
                </div>} */}
            <CSSTransition
                mountOnEnter
                unmountOnExit
                in={truncatedFlyer}
                timeout={500}
                classNames="flyer-transition"
            >
                <FlyerDisplayer
                    style={{
                        zIndex: 100
                    }}
                    src={flyerSrc}
                    HandleFlyerClick={HandleFlyerClick}
                    setTruncatedFlyer={setTruncatedFlyer} />
            </CSSTransition>
            <CSSTransition
                mountOnEnter
                unmountOnExit
                in={params.displayLoadingScreen}
                appear={true}
                timeout={2000}
                classNames="loading-transition"
            >
                <div className="loading-screen">
                    <h1 className="loading-title">Boreas Cruise</h1>
                </div>
            </CSSTransition>
            {!isMobile &&
                <div>
                    <Header
                        isMobile={isMobile}
                        displayHeader={displayHeader}
                    />
                    <CSSTransition
                        mountOnEnter
                        unmountOnExit
                        in={!params.videoPlaying}
                        timeout={2000}
                        classNames="hero-transition"
                    >
                        <div className="hero">
                            <h1 id="main-title">LE BOREAS</h1>
                            <HashLink
                                smooth to="#section2"
                                className="arrow-wrapper"
                            >
                                <img src={downArrow} className="down-arrow"
                                />
                            </HashLink>
                        </div>
                    </CSSTransition>
                    <CSSTransition
                        mountOnEnter
                        unmountOnExit
                        in={params.videoPlaying}
                        timeout={1000}
                        classNames="video-transition"
                    >
                        <div className="hero">
                            <div id="video-div">
                                <ReactPlayer
                                    width="100%"
                                    height="100%"
                                    playing={params.videoPlaying}
                                    volume={0}
                                    muted={true}
                                    onReady={fadeVideo}
                                    url='https://www.youtube.com/watch?v=-PyWMTiiBI0' />
                            </div>
                        </div>
                    </CSSTransition>
                    <div id="section2">
                        {
                            stared &&
                            <div
                                style={{
                                    width: "40%",
                                    marginTop: "5vh"
                                }}
                            >
                                <DisplayEvent truncatedFlyer={truncatedFlyer}
                                    handleFrameClick={handleFrameClick}
                                    pinkCruiseFlyer={pinkCruiseFlyer}
                                    eventToDisplay={stared}
                                    language={params.language}
                                    setChosenDate={params.setChosenDate}
                                    chosenDate={params.chosenDate}
                                    servPort={params.servPort}
                                />
                            </div>
                        }
                        <div id="right-column">
                            <Link to="/events"
                                className="column-block"
                                onClick={() => {
                                    setTimeout(() => {
                                        window.scrollTo({ top: 0, behavior: "smooth" })
                                    }, 50)
                                }
                                }
                            >
                                <h2>PROGRAMMATION</h2>
                            </Link>
                            <Link to="/contact"
                                className="column-block"
                                onClick={() => {
                                    setTimeout(() => {
                                        window.scrollTo({ top: 0, behavior: "smooth" })
                                    }, 50)
                                }
                                }
                            >
                                <h2>PRIVATISATION</h2>
                            </Link>
                        </div>
                    </div>
                </div>

            }
            {
                isMobile &&
                <div>
                    <Header
                        isMobile={isMobile}
                        displayHeader={displayHeader}
                        setDisplayMobileMenu={setDisplayMobileMenu}
                        displayMobileMenu={displayMobileMenu}
                        burgerOrClose={burgerOrClose}
                    />
                    <div className="hero-mobile">
                        <h1 id="main-title">LE BOREAS</h1>
                        <CSSTransition
                            mountOnEnter
                            unmountOnExit
                            in={params.videoPlaying}
                            timeout={1000}
                            classNames="video-transition"
                        >
                            <div id="video-div">
                                <ReactPlayer
                                    width="100%"
                                    height="100%"
                                    playing={params.videoPlaying}
                                    volume={0}
                                    muted={true}
                                    onReady={fadeVideo}
                                    url='https://www.youtube.com/watch?v=-PyWMTiiBI0' />
                            </div>
                        </CSSTransition>
                        <HashLink
                            smooth to="#section2"
                            className="arrow-wrapper"
                        >
                            <img src={downArrow} className="down-arrow"
                            />
                        </HashLink>
                    </div>
                    <div id="section2">
                        {
                            stared &&
                            <DisplayEvent truncatedFlyer={truncatedFlyer}
                                handleFrameClick={handleFrameClick}
                                pinkCruiseFlyer={pinkCruiseFlyer}
                                eventToDisplay={stared}
                                language={params.language}
                                setChosenDate={params.setChosenDate}
                                chosenDate={params.chosenDate}
                                servPort={params.servPort}
                            />
                        }
                        <div id="right-column">
                            <Link to="/events"
                                className="column-block"
                                onClick={() => {
                                    setTimeout(() => {
                                        window.scrollTo({ top: 0, behavior: "smooth" })
                                    }, 50)
                                }
                                }
                            >
                                <h2>PROGRAMMATION</h2>
                            </Link>
                            <Link to="/contact"
                                className="column-block"
                                onClick={() => {
                                    setTimeout(() => {
                                        window.scrollTo({ top: 0, behavior: "smooth" })
                                    }, 50)
                                }
                                }
                            >
                                <h2>PRIVATISATION</h2>
                            </Link>
                        </div>
                    </div>
                </div>
            }
            <Footer />
        </div>
    )
}

/*
Lien pour l'éscale https://www.haropaports.com/fr/paris/la-gare-bibilotheque-nationale-de-france
*/

export default Home

/*

<div id="section2">
                        <CSSTransition
                            mountOnEnter
                            unmountOnExit
                            in={!truncatedFlyer}
                            timeout={500}
                            classNames="flyer-transition"
                        >
                            <div id="left-column">
                                <div
                                    onClick={() => handleFrameClick(pinkCruiseFlyer)}
                                    id="picture-frame">
                                     <img src={pinkCruiseFlyer} id="picture-in-frame"
                                        onClick={() => handleFrameClick(pinkCruiseFlyer)}
                                    />
                                    </div>
                                    <h3 id="main-event-title">La Pink Cruise</h3>
                                    <p className="main-event-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas dui nisi, bibendum non leo at, luctus lobortis nisi. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Morbi elementum vulputate mollis. Curabitur vestibulum risus vel finibus hendrerit. Aliquam erat volutpat.</p>
                                    <div
                                        id="reastaurant-menu-text"
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'left',
                                            color: 'white',
                                            marginLeft: "5%",
                                            position: 'relative',
                                            width: "90%"
                                        }}
                                    >
                                        <p className="">Restauration sur place</p>
                                        <p style={{
                                            marginLeft: "4%"
                                        }}>(</p>
                                        <p
                                            style={{
                                                color: "rgb(242, 135, 5)"
                                            }}
                                        >menu</p>
                                        <p>)</p>
                                    </div>
                                    <div
                                        id="escale-text"
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'left',
                                            color: 'white',
                                            marginLeft: "5%",
                                            position: 'relative',
                                            width: "90%",
                                            marginTop: "4%"
                                        }}
                                    >
                                        <p className="">Escale :</p>
                                        <p
                                            style={{
                                                color: "rgb(242, 135, 5)",
                                                marginLeft: "4%"
                                            }}
                                        >Grennelle</p>
                                    </div>
                                </div>
                            </CSSTransition>
                             <div id="right-column">
                                <div className="column-block">
                                    <h2>PROGRAMMATION</h2>
                                </div>
                                <div className="column-block">
                                    <h2>PRIVATISATION</h2>
                                </div>
                            </div>
                        </div>

                        */