import { Link } from "react-router-dom"
import workInProgress from "../icons/work-in-progress.svg"

function WarningSection(params) {


    return (
        <div id="warning-section">
            <img id="warning-img" src={workInProgress} />
            <p className="warning-text">Cette section est en construction...</p>
            <p className="warning-text">Elle sera disponible bientôt !
            </p>
            <p className="warning-text">N'hésitez pas à revenir régulièrement,
                des mises à jour régulières seront apportées au site
            </p>
            <p className="warning-text">Pour toute demande de renseignements, réservation, ou pour signaler un problème avec le site n'hésitez pas à contacter l'équipe:&nbsp;
                <a
                    style={{
                        color: "rgb(242, 135, 5)"
                    }}
                    href="mailto: zara@boreas.paris">zara@boreas.paris</a>
            </p>
            <Link to="/">
                <button
                    id="back-to-homepage"
                >Retour à l'acceuil</button>
            </Link>
        </div>
    )
}

export default WarningSection